import { Route, Routes, useNavigate } from 'react-router';
import React, { useEffect } from 'react';

import { PageLink } from '../Constants/PageLink';
import { AuthLayout } from '../Layouts';
import {
  CreateOfferPage,
  CustomerManagementPage,
  InsuranceCompaniesPage,
  OfferTemplatesPage,
  OfferPdfUiPage,
  RegisteredOffersPage,
  UserManagementPage,
  OfferJpegUiPage
} from '../Pages';

const AuthRouter = () => {
  return (
    <Routes>
      <Route
        path={PageLink.InsuranceCompanies}
        element={
          <AuthLayout>
            <InsuranceCompaniesPage />
          </AuthLayout>
        }
      />
      <Route
        path={PageLink.OfferTemplates}
        element={
          <AuthLayout>
            <OfferTemplatesPage />
          </AuthLayout>
        }
      />
      <Route
        path={PageLink.UserManagement}
        element={
          <AuthLayout>
            <UserManagementPage />
          </AuthLayout>
        }
      />
      <Route
        path={PageLink.RegisteredOffers}
        element={
          <AuthLayout>
            <RegisteredOffersPage />
          </AuthLayout>
        }
      />

      <Route
        path={PageLink.Customers}
        element={
          <AuthLayout>
            <CustomerManagementPage />
          </AuthLayout>
        }
      />

      <Route
        path={PageLink.CreateOffer}
        element={
          <AuthLayout>
            <CreateOfferPage />
          </AuthLayout>
        }
      />

      <Route path={PageLink.OfferPdfDownload} Component={OfferPdfUiPage} />
      <Route path={PageLink.OfferJpegDownload} Component={OfferJpegUiPage} />

      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

export default AuthRouter;

const NoMatch = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate({ pathname: PageLink.CreateOffer });
  }, []);
  return null;
};
