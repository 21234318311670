import {
  IOfferType,
  IOfferTypeString
} from '../Types/Interfaces/OfferTypeInterfaces';
import { parseJson, stringifyJson } from './jsonHelpers';

export const mapOfferTypesToService = (
  data: Array<IOfferType> | undefined
): Array<IOfferTypeString> | undefined => {
  return data?.map((item) => mapOfferTypeToService(item));
};

export const mapOfferTypesToUse = (
  data: Array<IOfferTypeString> | undefined
): Array<IOfferType> | undefined => {
  return data?.map((item) => mapOfferTypeToUse(item));
};

export const mapOfferTypeToService = (
  data: IOfferType | undefined
): IOfferTypeString => {
  return {
    id: data?.id || -1,
    creator: data?.creator,
    type_name: data?.type_name || '',
    date_created: data?.date_created,
    bestOfferId: data?.bestOfferId || '',
    specials: stringifyJson(data?.specials),
    date_last_updated: data?.date_last_updated,
    ins_companies: stringifyJson(data?.ins_companies),
    pay_type: stringifyJson(data?.pay_type || ''),
    template_headers: stringifyJson(data?.template_headers)
  };
};

export const mapOfferTypeToUse = (
  data: IOfferTypeString | undefined
): IOfferType => {
  return {
    id: data?.id || -1,
    creator: data?.creator,
    type_name: data?.type_name || '',
    date_created: data?.date_created,
    specials: parseJson(data?.specials),
    pay_type: parseJson(data?.pay_type),
    bestOfferId: data?.bestOfferId || '',
    date_last_updated: data?.date_last_updated,
    ins_companies: parseJson(data?.ins_companies),
    template_headers: parseJson(data?.template_headers)
  };
};
