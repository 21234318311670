import { DefaultOptionType } from 'rc-select/es/Select';
import { Cascader } from 'antd';

import { ISelectBoxProp } from './types';
import styles from './index.module.scss';

const SWSelectBox = ({
  value,
  status,
  options,
  multiple,
  onSearch,
  onChange,
  disabled,
  fieldNames,
  placeholder,
  maxTagCount
}: ISelectBoxProp) => {
  const showSearchFilter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) =>
        (option?.label as string)
          ?.toLowerCase()
          .indexOf((inputValue as string)?.toLowerCase()) > -1
    );

  return (
    <Cascader
      value={value}
      status={status}
      options={options}
      disabled={disabled}
      onChange={onChange}
      multiple={multiple}
      fieldNames={fieldNames}
      placeholder={placeholder}
      maxTagCount={maxTagCount}
      className={styles.selectBox}
      onSearch={(value) => onSearch?.(value)}
      showSearch={{ filter: showSearchFilter }}
    />
  );
};

export default SWSelectBox;
