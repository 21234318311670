import {
  CreateCustomerRequestDto,
  GetCustomersRequestDto,
  UpdateCustomerRequestDto
} from '../../HttpService/DTOs/requestDtos';

import { makeAutoObservable } from 'mobx';
import { toast } from 'react-hot-toast';
import { map, uniqBy } from 'lodash';

import { GetCustomersResponseDto } from '../../HttpService/DTOs/responseDtos';
import { ICustomer } from '../../Types/Interfaces/CustomerInterfaces';
import { CustomerType } from '../../Types/Enums/CustomerEnums';
import HttpService from '../../HttpService';
import { ActionCallback } from '../types';

const mapCustomer = (data: ICustomer): ICustomer => {
  return {
    ...data,
    typeString: data?.tc ? 'Bireysel' : 'Kurumsal',
    typeEnum: data?.tc ? CustomerType.Individual : CustomerType.Corporate
  };
};

export default class CustomerStore {
  constructor(private isAuthenticated: boolean) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.fillCustomers();
  }

  customersLoading: boolean = false;
  customers: Array<ICustomer> = [];

  customerUpdated: boolean = false;
  customerCreated: boolean = false;

  fillCustomers(payload?: GetCustomersRequestDto) {
    if (this.isAuthenticated) {
      this.customersLoading = true;
      HttpService.GetCustomers(payload)
        .then((res) => {
          if (!res?.data?.detail) {
            const customerList: Array<ICustomer> = map(
              res?.data,
              (c: ICustomer) => mapCustomer(c)
            );
            this.customers = uniqBy(customerList, (c) => c.id);
          }
        })
        .catch((e) => console.log('fillCustomers', e))
        .finally(() => (this.customersLoading = false));
    }
  }

  createCustomer(
    payload: CreateCustomerRequestDto,
    callback?: ActionCallback<GetCustomersResponseDto>
  ) {
    HttpService.CreateCustomer(payload)
      .then((res) => {
        const resData = res?.data;
        if (!resData?.detail) {
          this.fillCustomers();
          this.customerCreated = true;
          toast.success('Müşteri oluşturuldu');
          callback?.onSuccess?.(mapCustomer(resData));
        }
      })
      .catch((e) => {
        console.log('createCustomer', e);
        callback?.onError?.(e);
      })
      .finally(() =>
        setTimeout(() => {
          this.customerCreated = false;
        }, 1000)
      );
  }

  updateCustomer(
    payload: UpdateCustomerRequestDto,
    callback?: ActionCallback<any>
  ) {
    HttpService.UpdateCustomer(payload)
      .then((res) => {
        const resData = res?.data;
        if (!resData?.detail) {
          this.fillCustomers();
          this.customerUpdated = true;
          toast.success('Müşteri güncellendi');
          callback?.onSuccess?.(mapCustomer(resData));
        }
      })
      .catch((e) => {
        console.log('updateCustomer', e);
        callback?.onError?.(e);
      })
      .finally(() =>
        setTimeout(() => {
          this.customerUpdated = false;
        }, 1000)
      );
  }

  deleteCustomer(customer: ICustomer) {
    HttpService.DeleteCustomer({
      id: customer.id,
      customerType: customer.typeEnum
    })
      .then((res) => {
        const resData = res?.data;
        if (!resData?.detail) {
          this.customers = this.customers.filter((c) => c.id !== customer.id);
          toast.success('Müşteri silindi');
        }
      })
      .catch((e) => console.log('deleteCustomer', e));
  }
}
