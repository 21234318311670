import { useMemo, useState } from 'react';
import { Divider, Image, Table } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { UploadChangeParam } from 'antd/es/upload';
import { ColumnsType } from 'antd/es/table';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { createCompanyFormRules } from '../../../FormValidation/Rules/createCompanyFormRules';
import { SWEditCompanyModal, SWCreateCompanyModal } from '../../../Components/Composite';
import { handleFileChange } from '../../../Helpers/uploadHelper';
import { ICompany } from '../../../Types/Interfaces/ICompany';
import { companyStore } from '../../../GlobalStore';
import { SWButton } from '../../../Components/UI';
import styles from './index.module.scss';

const InsuranceCompaniesPage = observer(() => {
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<ICompany>();
  const [isCreateCompanyModalVisible, setIsCreateCompanyModalVisible] = useState<boolean>(false);

  const {
    control,
    formState: { isValid },
    setValue,
    trigger,
    reset,
    getValues
  } = useForm({
    resolver: yupResolver(createCompanyFormRules)
  });

  const companyList = useMemo(() => {
    return companyStore.companies;
  }, [toJS(companyStore.companies)]);

  const resetModal = () => setSelectedCompany(undefined);

  const handleChange = (info: UploadChangeParam) => {
    handleFileChange(info, (url) => {
      setValue('logoUrl', url);
      trigger('logoUrl');
    });
  };

  const createCompany = () => {
    setCreateLoading(true);
    companyStore.createCompany(
      {
        name: getValues('name'),
        logo: getValues('logoUrl')
      },
      {
        onSuccess: () => reset(),
        onFinally: () => setCreateLoading(false)
      }
    );
  };

  const deleteCompany = (companyId: string) => {
    companyStore.deleteCompany(companyId);
  };

  const companyTableColumns: ColumnsType<ICompany> = [
    {
      title: 'Firma',
      dataIndex: 'name',
      key: 'name',
      width: '90%',
      sorter: (a, b) => a.name.length - b.name.length,
      render: (_, company) => (
        <div className={styles.actionsContainer}>
          <div style={{ width: 100 }}>
            <Image
              width={80}
              height={'auto'}
              preview={false}
              src={company.logo}
            />
          </div>
          <div className={styles.companyName}>{company.name}</div>
        </div>
      )
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: 200,
      render: (_, company) => (
        <div className={styles.actionsContainer}>
          <SWButton
            outlined
            type={'secondary'}
            content={'Düzenle'}
            onClick={() => setSelectedCompany(company)}
          />
          {/* <SWButton
            content={'Sil'}
            outlined
            onClick={() => deleteCompany(company.id)}
          /> */}
        </div>
      )
    }
  ];

  const handleOpenCreateCompanyModal = () => {
    setIsCreateCompanyModalVisible(true);
  }

  const handleCloseCreateCompanyModal = () => {
    setIsCreateCompanyModalVisible(false);
  }

  return (
    <>
      <Divider>
        <h2>Anlaşmalı Sigorta Şirketleri</h2>
      </Divider>
      <div className={styles.addCompanyContainer}>
        <SWButton
          disabled={isCreateCompanyModalVisible}
          content='ŞİRKET EKLE'
          size='middle'
          type='primary'
          onClick={handleOpenCreateCompanyModal}
        />
      </div>
      <Table
        bordered
        dataSource={toJS(companyList)}
        columns={companyTableColumns}
        loading={companyStore.companiesLoading}
        rowKey={(company) => `${company.id}-${company.name}`}
      // footer={() => (
      //   <div className={styles.actionsContainer}>
      //     <div style={{ width: 100 }}>
      //       <Upload
      //         maxCount={1}
      //         name="avatar"
      //         className={styles.uploadCard}
      //         accept={'image/png,image/jpeg'}
      //         showUploadList={false}
      //         listType="picture-card"
      //         onChange={handleChange}>
      //         {getValues('logoUrl') ? (
      //           <img
      //             alt="avatar"
      //             style={{
      //               width: '100%',
      //               height: '100%',
      //               objectFit: 'cover'
      //             }}
      //             src={getValues('logoUrl')}
      //           />
      //         ) : (
      //           <span>Upload</span>
      //         )}
      //       </Upload>
      //     </div>
      //     <div className={styles.companyName}>
      //       <Controller
      //         name={'name'}
      //         control={control}
      //         render={({ field }) => (
      //           <Input {...field} placeholder={'Firma Adı'} />
      //         )}
      //       />
      //     </div>
      //
      //     <SWButton
      //       content={'Ekle'}
      //       disabled={!isValid}
      //       loading={createLoading}
      //       className={styles.grid}
      //       onClick={createCompany}
      //     />
      //   </div>
      // )}
      />

      <SWEditCompanyModal
        onOk={resetModal}
        onCancel={resetModal}
        company={selectedCompany}
        title={selectedCompany?.name || ''}
        visible={typeof selectedCompany !== 'undefined'}
      />

      <SWCreateCompanyModal
        onOk={handleCloseCreateCompanyModal}
        onCancel={handleCloseCreateCompanyModal}
        visible={isCreateCompanyModalVisible}
      />
    </>
  );
});

export default InsuranceCompaniesPage;
