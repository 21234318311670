import { makeAutoObservable } from 'mobx';

import { IUser } from '../../Types/Interfaces/UserInterfaces';
import HttpService from '../../HttpService';
import {
  CreateUserRequestDto,
  UpdateUserRequestDto
} from '../../HttpService/DTOs/requestDtos';
import { ActionCallback } from '../types';
import { toast } from 'react-hot-toast';

export default class UserStore {
  constructor(private isAuthenticated: boolean) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.fillUsers();
  }

  usersLoading: boolean = false;
  users: Array<IUser> = [];

  fillUsers() {
    if (this.isAuthenticated) {
      this.usersLoading = true;
      HttpService.GetUsers()
        .then((res) => {
          this.users = res?.data;
        })
        .catch((e) => console.log('FillUsers', e))
        .finally(() => (this.usersLoading = false));
    }
  }

  createUser(payload: CreateUserRequestDto, callback?: ActionCallback<any>) {
    HttpService.CreateUser(payload)
      .then((res) => {
        const resData = res?.data;
        if (!resData?.detail) {
          this.fillUsers();
          toast.success('Kullanıcı oluşturuldu');
          callback?.onSuccess?.();
        }
      })
      .catch((e) => {
        console.log('createUser', e);
        callback?.onError?.(e);
      })
      .finally(() => callback?.onFinally?.());
  }

  deleteUser(userId: number) {
    HttpService.DeleteUser({ userId })
      .then((res) => {
        if (!res?.data?.detail) {
          this.users = this.users.filter((u) => u.id !== userId);
          toast.success('Kullanıcı silindi');
        }
      })
      .catch((e) => console.log('Delete User', e));
  }

  updateUser(payload: UpdateUserRequestDto) {
    HttpService.UpdateUser(payload)
      .then((res) => {
        if (!res?.data?.detail) {
          this.fillUsers();
          toast.success('Kullanıcı güncellendi');
        }
      })
      .catch((e) => console.log('Update User', e));
  }
}
