import { useCallback, useEffect, useMemo, useState } from 'react';

import { MinusCircleOutlined, PlusCircleFilled } from '@ant-design/icons';
import { NumberFormatBase, NumericFormat } from 'react-number-format';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Col, DatePicker, Input, Row } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment-timezone';
import cn from 'classnames';
import dayjs from 'dayjs';

import { addInputToOfferTemplateFormRules } from '../../../FormValidation/Rules/updateOfferTemplateFormRules';
import { IOfferTypeHeader } from '../../../Types/Interfaces/OfferTypeInterfaces';
import { availableInputTypes } from '../../../Constants/AvailableInputTypes';
import { formattedDateToDayJs } from '../../../Helpers/dateHelpers';
import { ITemplateHeaderFieldsProp } from './types';
import { SWButton, SWSelectBox } from '../../UI';
import styles from './index.module.scss';

const SWTemplateHeaderFields = observer(
  ({
    onUpdate,
    offerType,
    isCopyOffer,
    inputDisable,
    onInputUpdated,
    isOfferHistory
  }: ITemplateHeaderFieldsProp) => {
    const [addedInputs, setAddedInputs] = useState<Array<IOfferTypeHeader>>();
    const [showAddForm, setShowAddForm] = useState(false);

    const {
      control,
      formState: { isValid },
      reset,
      trigger,
      setValue,
      getValues
    } = useForm({
      resolver: yupResolver(addInputToOfferTemplateFormRules)
    });

    useEffect(() => {
      if (!showAddForm) {
        reset();
      }
    }, [showAddForm]);

    const template = useMemo(() => {
      return offerType;
    }, [offerType]);

    useEffect(() => {
      if (template?.template_headers) {
        setAddedInputs(template.template_headers);
        onUpdate?.(template.template_headers);
      }
    }, [template]);

    const addHeaderInput = () => {
      setShowAddForm(false);
      onUpdate?.([...(addedInputs || []), getValues()]);
      setAddedInputs((prev) => [...(prev || []), getValues()]);
    };

    const renderDeleteInputIcon = (label: string) => (
      <MinusCircleOutlined
        onClick={() => {
          const newInputs = addedInputs?.filter((i) => i.label !== label);
          setAddedInputs(newInputs);
          onUpdate?.(newInputs || []);
          onInputUpdated?.({ label, value: undefined });
        }}
        className={styles.minusIcon}
      />
    );

    const render = useCallback(() => {
      if (!addedInputs?.length)
        return (
          <div className={cn(styles.container, styles.notFoundContainer)}>
            <div>Ürün özelliği bulunmamaktadır.</div>
          </div>
        );

      return addedInputs?.map((field, index) => {
        const { type, label, value } = field;
        if (type === 'numeric' || (isCopyOffer && Number(value))) {
          return (
            <div className={styles.container} key={index}>
              <label className={styles.inputPrefix}>{label}</label>
              <div className={styles.inputContainer}>
                <NumberFormatBase
                  placeholder={label}
                  customInput={Input}
                  disabled={inputDisable}
                  defaultValue={isCopyOffer ? value : undefined}
                  onValueChange={(values) =>
                    onInputUpdated?.({ label, value: values.formattedValue })
                  }
                />
                {renderDeleteInputIcon(label)}
              </div>
            </div>
          );
        } else if (type === 'money' || (isCopyOffer && value?.includes('₺'))) {
          return (
            <div className={styles.container} key={index}>
              <label className={styles.inputPrefix}>{label}</label>
              <div className={styles.inputContainer}>
                <NumericFormat
                  prefix={`₺ `}
                  decimalScale={2}
                  placeholder={label}
                  customInput={Input}
                  decimalSeparator={','}
                  thousandSeparator={'.'}
                  disabled={inputDisable}
                  defaultValue={isCopyOffer ? value : undefined}
                  onValueChange={(values) =>
                    onInputUpdated?.({ label, value: values.formattedValue })
                  }
                />
                {renderDeleteInputIcon(label)}
              </div>
            </div>
          );
        } else if (
          type === 'date' ||
          (isCopyOffer && moment(formattedDateToDayJs(value)).isValid())
        ) {
          return (
            <div className={styles.container} key={index}>
              <label className={styles.inputPrefix}>{label}</label>
              <div className={styles.inputContainer}>
                <DatePicker
                  placeholder={label}
                  disabled={inputDisable}
                  defaultValue={
                    isCopyOffer && moment(formattedDateToDayJs(value)).isValid()
                      ? dayjs(formattedDateToDayJs(value))
                      : undefined
                  }
                  onChange={(e) =>
                    onInputUpdated?.({ label, value: e?.format('DD-MM-YYYY') })
                  }
                />
                {renderDeleteInputIcon(label)}
              </div>
            </div>
          );
        }
        return (
          <div className={styles.container} key={index}>
            <label className={styles.inputPrefix}>{label}</label>
            <div className={styles.inputContainer}>
              <Input
                placeholder={label}
                disabled={inputDisable}
                defaultValue={isCopyOffer ? value : undefined}
                value={isOfferHistory ? value : undefined}
                onChange={(e) =>
                  onInputUpdated?.({ label, value: e?.currentTarget?.value })
                }
              />
              {renderDeleteInputIcon(label)}
            </div>
          </div>
        );
      });
    }, [addedInputs]);

    return (
      <>
        {render()}
        {!showAddForm && !isOfferHistory && (
          <div
            onClick={() => setShowAddForm(true)}
            className={styles.addButtonContainer}>
            <div className={styles.addButtonText}>Yeni Ekle</div>
            <div>
              <PlusCircleFilled className={styles.addIcon} />
            </div>
          </div>
        )}
        {showAddForm && (
          <>
            <Row gutter={16}>
              <Col span={12}>
                <label className={styles.label}>Özellik Adı</label>
                <Controller
                  control={control}
                  name={'label'}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder="Ürüne ait eklenmek istenen özelliğin adı"
                    />
                  )}
                />
              </Col>
              <Col span={12}>
                <label className={styles.label}>Özellik Değeri</label>
                <SWSelectBox
                  value={undefined}
                  options={availableInputTypes}
                  placeholder="Ürüne ait eklenmek istenen özelliğin değeri"
                  onChange={(value) => {
                    setValue('type', (value ? value.join('') : '') as any);
                    trigger('type');
                  }}
                />
              </Col>
            </Row>
            <br />
            <Row gutter={16} className={styles.addFormButtonContainer}>
              <Col>
                <SWButton
                  outlined
                  type={'secondary'}
                  content={'İptal Et'}
                  onClick={() => setShowAddForm(false)}
                />
              </Col>
              <Col>
                <SWButton
                  content={'Kaydet'}
                  disabled={!isValid}
                  onClick={addHeaderInput}
                />
              </Col>
            </Row>
          </>
        )}
      </>
    );
  }
);

export default SWTemplateHeaderFields;
