import yup from '../../Utils/yup';

export const createOrUpdateUserFormRules = (isCreateForm?: boolean) =>
  yup.object().shape({
    name: yup.string().required().onlyAlphabetic().min(3),
    email: yup.string().required().realEmail(),
    surname: yup.string().required().onlyAlphabetic().min(3),
    bagli_sube_id: yup.string().min(3).required(),
    tc: yup.string().required().identityNumber(),
    position: yup.string().required().min(3),
    hashed_password: isCreateForm
      ? yup.string().strongPassword().required()
      : yup.string().strongPassword().optional()
  });
