import { DatePicker } from 'antd';
import { IDatePickerProp } from './types';

const SWDatePicker = ({
  label,
  width,
  format,
  size,
  onSelect
}: IDatePickerProp) => {

  return (
    <div>
      {!!label && <div>{label}</div>}
      <div style={{ width: `${width}px` }}>
        <DatePicker
          format={format || 'DD.MM.YYYY'}
          size={size || 'middle'}
          onChange={(date: any) => {
            onSelect(date?.format('DD.MM.YYYY') || "");
          }}
        />
      </div>
    </div>
  );
};

export default SWDatePicker;
