import React, { useEffect, useState } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Input } from 'antd';
import cn from 'classnames';

import { createOrUpdateUserFormRules } from '../../../FormValidation/Rules/createOrUpdateUserFormRules';
import { SWFormErrorMessage } from '../../UI/SWFormErrorMessage';
import { UserFormInputType, UserFormProp } from './types';
import { DefaultOptionType } from 'rc-select/es/Select';
import { SWInput, SWSelectBox } from '../../UI';
import HttpService from '../../../HttpService';
import styles from './index.module.scss';

const inputHeight = 32;
const SWUserForm = ({
  inputs,
  handleValid,
  handleInput,
  isCreateForm
}: UserFormProp) => {
  const [branches, setBranches] = useState<Array<DefaultOptionType>>();

  const {
    trigger,
    control,
    setValue,
    getValues,
    formState: { isValid, errors }
  } = useForm({
    mode: 'all',
    resolver: yupResolver(createOrUpdateUserFormRules(isCreateForm))
  });

  useEffect(() => {
    HttpService.GetBranches().then((res) => {
      const resData = res?.data;
      const optionList = resData?.map((i) => ({ label: i.name, value: i.id }));
      setBranches(optionList);
    });
  }, []);

  useEffect(() => {
    if (inputs) {
      setValue('tc', inputs.tc);
      setValue('name', inputs.name);
      setValue('email', inputs.email);
      setValue('surname', inputs.surname);
      setValue('position', inputs.position);
      setValue('bagli_sube_id', inputs.bagli_sube_id);

      trigger('hashed_password');
    }
  }, [inputs]);

  useEffect(() => {
    handleValid(isValid);
  }, [isValid]);

  const updateInput = (field: keyof UserFormInputType, value: string) => {
    // @ts-ignore
    setValue(field, value);
    trigger(field as any);
    handleInput(getValues() as any);
  };

  return (
    <>
      <Col span={12}>
        <Controller
          control={control}
          name={'name'}
          render={({ field }) => (
            <>
              <div className={styles.requiredLabel}>Ad</div>
              <SWInput
                id="name"
                {...field}
                height={inputHeight}
                style={{ border: { borderWidth: 1 } }}
                onChange={(value) => updateInput('name', value)}
              />
              <SWFormErrorMessage message={errors?.name?.message} />
            </>
          )}
        />
      </Col>
      <Col span={12}>
        <Controller
          control={control}
          name={'surname'}
          render={({ field }) => (
            <>
              <div className={styles.requiredLabel}>Soyad</div>
              <SWInput
                {...field}
                id="surname"
                height={inputHeight}
                style={{ border: { borderWidth: 1 } }}
                onChange={(value) => updateInput('surname', value)}
              />
              <SWFormErrorMessage message={errors?.surname?.message} />
            </>
          )}
        />
      </Col>
      <Col span={12}>
        <Controller
          control={control}
          name={'tc'}
          render={({ field }) => (
            <>
              <div className={styles.requiredLabel}>T.C. Kimlik No</div>
              <SWInput
                id={'tc'}
                {...field}
                height={inputHeight}
                format="###########"
                style={{ border: { borderWidth: 1 } }}
                onChange={(value) => updateInput('tc', value)}
              />
              <SWFormErrorMessage message={errors?.tc?.message} />
            </>
          )}
        />
      </Col>
      <Col span={12}>
        <Controller
          control={control}
          name={'bagli_sube_id'}
          render={({ field }) => (
            <>
              <div className={styles.requiredLabel}>Şube</div>
              <SWSelectBox
                {...field}
                value={[field.value]}
                options={branches || []}
                onChange={(value) =>
                  updateInput('bagli_sube_id', value.join(''))
                }
              />
              <SWFormErrorMessage message={errors?.bagli_sube_id?.message} />
            </>
          )}
        />
      </Col>
      <Col span={12}>
        <Controller
          control={control}
          name={'position'}
          render={({ field }) => (
            <>
              <div className={styles.requiredLabel}>Pozisyon</div>
              <SWInput
                id="position"
                {...field}
                height={inputHeight}
                style={{ border: { borderWidth: 1 } }}
                onChange={(value) => updateInput('position', value)}
              />
              <SWFormErrorMessage message={errors?.position?.message} />
            </>
          )}
        />
      </Col>
      <Col span={12}>
        <Controller
          control={control}
          name={'email'}
          render={({ field }) => (
            <>
              <div className={styles.requiredLabel}>E-posta Adresi</div>
              <SWInput
                id="email"
                {...field}
                height={inputHeight}
                autoComplete="update-new-email"
                style={{ border: { borderWidth: 1 } }}
                onChange={(value) => updateInput('email', value)}
              />
              <SWFormErrorMessage message={errors?.email?.message} />
            </>
          )}
        />
      </Col>
      <Col span={12}>
        <Controller
          control={control}
          name={'hashed_password'}
          render={({ field }) => (
            <>
              <div className={cn({ [styles.requiredLabel]: isCreateForm })}>
                {isCreateForm ? 'Şifre' : 'Yeni Şifre'}
              </div>
              <Input.Password
                {...field}
                autoComplete="update-new-password"
                onChange={(e) =>
                  updateInput('hashed_password', e.currentTarget.value)
                }
              />
              <SWFormErrorMessage message={errors?.hashed_password?.message} />
            </>
          )}
        />
      </Col>
    </>
  );
};

export default SWUserForm;
