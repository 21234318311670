import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

interface IItems {
  [key: string]: any;
}

export default class RouterStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    makePersistable(this, {
      name: 'RouterItems',
      properties: ['items'],
      storage: (typeof window !== 'undefined' && localStorage) || undefined
    });
  }

  items: IItems = {};

  setItem<P>(key: string, value: P) {
    const hashedValue = JSON.stringify(value);
    this.items = {
      ...this.items,
      [key]: hashedValue
    };
  }

  getItem<P>(
    key: string,
    onCompleted: ((data: P | undefined) => void) | undefined = undefined
  ): P {
    let decryptedData = null;
    let matchedItem = undefined;
    if (this.items[key]) {
      matchedItem = this.items[key];
    }
    if (matchedItem) {
      decryptedData = matchedItem;
      if (decryptedData && typeof decryptedData === 'string') {
        decryptedData = JSON.parse(decryptedData);
        onCompleted && onCompleted(decryptedData);
      } else {
        onCompleted && onCompleted(undefined);
      }
    } else {
      onCompleted && onCompleted(undefined);
    }
    // this.removeItem(key);
    return decryptedData;
  }

  removeItem(key: string) {
    if (this.items[key]) {
      delete this.items[key];
    }
  }
}
