import { IOfferTypeCompany } from '../Types/Interfaces/OfferTypeInterfaces';
import { companyStore } from '../GlobalStore';

export const mapCompanyLogo = (companies: Array<IOfferTypeCompany>) => {
  return companies?.map((c) => {
    const findCompany = companyStore.companies?.find(
      (company) => company.id === c.id
    );
    const logo = findCompany?.logo || c.logo;
    return { ...c, logo };
  });
};
