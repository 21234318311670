import yup from '../../Utils/yup';

export const createOrUpdateIndividualCustomerFormRules = yup.object().shape({
  isim_soyisim: yup.string().min(3).required(),
  tc: yup.string().identityNumber().required(),
  eposta: yup.string().realEmail().required(),
  telefon: yup.string().phoneNumber(false).required(),
  dogum_gunu: yup.string().birthDate().required(),
  police: yup.string(),
  customerType: yup.string()
});
