import React, { useEffect, useMemo, useState } from 'react';

import { Col, Modal, Row } from 'antd';

import SWCorporateCustomerForm from '../../../Components/Composite/SWCorporateCustomerForm';
import { CreateCustomerRequestDto } from '../../../HttpService/DTOs/requestDtos';
import { ICustomer } from '../../../Types/Interfaces/CustomerInterfaces';
import { SWIndividualCustomerForm } from '../../../Components/Composite';
import { CustomerType } from '../../../Types/Enums/CustomerEnums';
import { customerStore } from '../../../GlobalStore';
import { SWRadio } from '../../../Components/UI';
import styles from './index.module.scss';

interface IProp {
  visible: boolean;
  selected?: ICustomer;
  onClose: (customer?: ICustomer) => void;
}

const SWCustomerModal = ({ selected, onClose, visible }: IProp) => {
  const [customerType, setCustomerType] = useState<CustomerType>(
    CustomerType.Individual
  );
  const [isFormValid, setIsFormValid] = useState<boolean>();
  const [input, setInput] = useState<CreateCustomerRequestDto>();

  useEffect(() => {
    if (visible && selected) {
      // @ts-ignore
      setInput(selected);
      setCustomerType(selected.typeEnum);
    }

    return () => {
      setInput(undefined);
    };
  }, [visible, selected]);

  const submit = () => {
    if (typeof selected !== 'undefined' && input) {
      const payload = {
        ...input,
        id: selected?.id || '',
        police: '',
        customerType
      };
      customerStore.updateCustomer(payload, {
        onSuccess: () => onClose(payload as unknown as ICustomer)
      });
    } else if (input) {
      customerStore.createCustomer(
        { police: '', ...input },
        {
          onSuccess: (data) => onClose?.(data as ICustomer)
        }
      );
    }
  };

  const updateSelectedCustomerType = (type: CustomerType) => {
    setCustomerType(type);
    setInput(undefined);
    setIsFormValid(false);
  };

  return (
    <Modal
      onOk={submit}
      width={'50%'}
      open={visible}
      destroyOnClose
      okText={'Kaydet'}
      onCancel={() => onClose()}
      okButtonProps={{ disabled: !isFormValid }}
      cancelText={'İptal'}
      title={
        selected
          ? `Müşteri Düzenle - ${selected?.isim_soyisim}`
          : 'Müşteri Oluştur'
      }>
      <Row gutter={[16, 16]} className={styles.modalBodyContainer}>
        {typeof selected === 'undefined' && (
          <Col span={24}>
            <div className={styles.centerRow}>
              <SWRadio
                type={'card'}
                label={'Bireysel'}
                checked={customerType === CustomerType.Individual}
                onCheck={() =>
                  updateSelectedCustomerType(CustomerType.Individual)
                }
              />

              <SWRadio
                type={'card'}
                label={'Kurumsal'}
                checked={customerType === CustomerType.Corporate}
                onCheck={() =>
                  updateSelectedCustomerType(CustomerType.Corporate)
                }
              />
            </div>
          </Col>
        )}
        {customerType === CustomerType.Individual && (
          <SWIndividualCustomerForm
            handleInput={setInput}
            handleValid={setIsFormValid}
            inputs={{ ...input, customerType } as CreateCustomerRequestDto}
          />
        )}
        {customerType === CustomerType.Corporate && (
          <SWCorporateCustomerForm
            handleInput={setInput}
            handleValid={setIsFormValid}
            inputs={{ ...input, customerType } as CreateCustomerRequestDto}
          />
        )}
      </Row>
    </Modal>
  );
};

export default SWCustomerModal;
