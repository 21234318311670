import { useEffect } from 'react';

import { SecurityScanOutlined, UserOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Input } from 'antd';
import cn from 'classnames';

import { SWFormErrorMessage } from '../../../Components/UI/SWFormErrorMessage';
import { loginFormRules } from '../../../FormValidation/Rules/loginFormRules';
import { LoginResponseDto } from '../../../HttpService/DTOs/responseDtos';
import { LoginRequestDto } from '../../../HttpService/DTOs/requestDtos';
import { useHttpService } from '../../../Hooks/useHttpService';
import { setAuthTokenToHeader } from '../../../HttpService';
import { PageLink } from '../../../Constants/PageLink';
import { SWButton } from '../../../Components/UI';
import { authStore } from '../../../GlobalStore';
import styles from './index.module.scss';

const LoginPage = () => {
  const {
    control,
    getValues,
    trigger,
    formState: { isValid, errors }
  } = useForm({
    mode: 'all',
    resolver: yupResolver(loginFormRules)
  });
  const [request, data, , loading] = useHttpService<
    LoginRequestDto,
    LoginResponseDto
  >('Login');

  useEffect(() => {
    if (data?.access_token) {
      window.localStorage.setItem(
        '@AuthToken',
        `${data?.token_type || 'Bearer'} ${data?.access_token}`
      );
      authStore.authToken = data?.access_token;
      setAuthTokenToHeader(data?.access_token);
      window.location.href = `${window.location.origin}${PageLink.CreateOffer}`;
    }
  }, [data]);

  useEffect(() => {
    trigger();
  }, []);

  const login = () => {
    // window.localStorage.setItem('@AuthToken', 'TestValue213');
    // const origin = window.location.origin;
    // window.location.href = `${origin}${PageLink.InsuranceCompanies}`;

    request(getValues());
  };

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <img alt="Siba Logo" src="/assets/logo.png" className={styles.logo} />
        <h2 className={styles.title}>Teklif Oluşturma Portalı</h2>
        <div className={styles.inputContainer}>
          <label htmlFor="username">Kullanıcı Adı</label>
          <Controller
            name={'username'}
            control={control}
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  size="large"
                  id="username"
                  prefix={<UserOutlined />}
                  placeholder="Kullanıcı Adı"
                />
                <SWFormErrorMessage message={errors?.username?.message} />
              </>
            )}
          />
        </div>

        <div className={styles.inputContainer}>
          <label htmlFor="password">Şifre</label>
          <Controller
            name={'password'}
            control={control}
            render={({ field }) => (
              <>
                <Input.Password
                  {...field}
                  size="large"
                  id="password"
                  placeholder="Şifre"
                  prefix={<SecurityScanOutlined />}
                />
                {errors?.password && (
                  <SWFormErrorMessage message="Şifrenizi kontrol ediniz." />
                )}
              </>
            )}
          />
        </div>

        <div className={cn(styles.inputContainer, styles.buttonContainer)}>
          <SWButton
            onClick={login}
            loading={loading}
            disabled={!isValid}
            htmlType={'submit'}
            content={'Giriş Yap'}
            className={styles.button}
          />
        </div>
      </div>
      <div
        style={{
          backgroundImage: 'url(./assets/login_img.jpg)'
        }}
        className={styles.imageContainer}
      />
    </div>
  );
};

export default LoginPage;
