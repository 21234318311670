//@ts-nocheck

import {
  CorporateCustomerFilterableFields,
  CustomerFilterType,
  ICustomer,
  IndividualCustomerFilterableFields
} from '../Types/Interfaces/CustomerInterfaces';
import { CustomerType } from '../Types/Enums/CustomerEnums';

interface IPayload {
  search?: string;
  filter?:
    | CorporateCustomerFilterableFields
    | IndividualCustomerFilterableFields;
}

export const createCustomersFilter = (payload: IPayload) => {
  const { search, filter } = payload;
  const hasFilterDb = typeof filter?.typeEnum !== 'undefined';
  const filterDb = hasFilterDb
    ? filter?.typeEnum === CustomerType.Corporate
      ? 'kurum'
      : 'birey'
    : undefined;

  let filters: Array<CustomerFilterType> = [];

  if (search) {
    if (filterDb) {
      filters.push({
        filterDb,
        filterType: filterDb === 'kurum' ? 'unvan' : 'isim_soyisim',
        filterValue: search
      });
    } else {
      filters.push({
        filterDb: 'birey',
        filterType: 'isim_soyisim',
        filterValue: search
      });
      filters.push({
        filterDb: 'kurum',
        filterType: 'unvan',
        filterValue: search
      });
    }
  }

  if (filter?.bagli_sube) {
    if (filterDb) {
      filters.push({
        filterDb,
        filterType: 'bagli_sube',
        filterValue: filter?.bagli_sube
      });
    } else {
      filters.push({
        filterDb: 'birey',
        filterType: 'bagli_sube',
        filterValue: filter?.bagli_sube
      });
      filters.push({
        filterDb: 'kurum',
        filterType: 'bagli_sube',
        filterValue: filter?.bagli_sube
      });
    }
  }

  if (filterDb) {
    filters.push({
      filterDb,
      filterType: '',
      filterValue: ''
    });
  }
  if (filter) {
    Object.keys(filter)?.map((f) => {
      if (filter[f] && f !== 'typeEnum' && f !== 'bagli_sube')
        filters.push({
          filterDb,
          filterType: f,
          filterValue: filter[f]
        });
    });
  }

  return {
    Filter: filters?.length > 1 ? filters.filter((f) => f.filterValue) : filters
  };
};

export const customerExcelHeaders: Array<{
  label: string;
  key: keyof ICustomer;
}> = [
  {
    label: 'Müşteri Tipi',
    key: 'typeString'
  },
  {
    label: 'Ad Soyad',
    key: 'isim_soyisim'
  },
  {
    label: 'Ünvan',
    key: 'unvan'
  },
  {
    label: 'T.C. Kimlik No',
    key: 'tc'
  },
  { label: 'Vergi Dairesi', key: 'vergi_dairesi' },
  { label: 'Vergi No', key: 'vergi_no' },
  { label: 'Şube', key: 'bagli_sube' },
  { label: 'GSM No', key: 'telefon' },
  { label: 'E-posta', key: 'eposta' }
];
