import React from 'react';

import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Image, Layout } from 'antd';

import { PageLink } from '../../Constants/PageLink';
import { SWSider } from '../../Components/UI';
import './styles.scss';

const { Content, Footer } = Layout;

const AuthLayout = observer(({ children }: any) => {
  return (
    <Layout hasSider className="mainLayout">
      <SWSider />
      <Layout className="contentLayout">
        <Content className="contentLayout__content">{children}</Content>
        <Footer className="contentLayout__footer">
          <Link to={PageLink.CreateOffer}>
            <Image
              height={60}
              width={'100%'}
              preview={false}
              alt={'Siba Sigorta Logo'}
              src={`/assets/signature_logo.png`}
            />
          </Link>
        </Footer>
      </Layout>
    </Layout>
  );
});

export default AuthLayout;
