import {
  authorizedRoutesForStandardUser,
  PageLink
} from '../Constants/PageLink';
import { RoleEnum } from '../Types/Enums/RoleEnum';
import { authStore } from '../GlobalStore';

export const isStandardUserAuthorizeDeniedFromRoute = (
  route: string | PageLink
) => {
  return (
    !authorizedRoutesForStandardUser.includes(route as PageLink) &&
    authStore.roles.includes(RoleEnum.Standard)
  );
};
