import { Key, useCallback, useEffect, useMemo, useState } from 'react';

import { Col, Input, InputNumber, Row, Table } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { NumericFormat } from 'react-number-format';
import { ColumnsType } from 'antd/es/table';
import { observer } from 'mobx-react';

import { IOfferTypeCompany } from '../../../Types/Interfaces/OfferTypeInterfaces';
import { SWButton, SWInput, SWSelectBox } from '../../UI';
import { generateUid } from '../../../Helpers/uidHelper';
import { ITemplateCompanyTableProp } from './types';
import { companyStore } from '../../../GlobalStore';
import styles from './index.module.scss';
import { sortBy } from 'lodash';

const SWTemplateCompanyTable = observer(
  ({
    pageSize,
    offerType,
    onUpdatedV2,
    isOfferHistory
  }: ITemplateCompanyTableProp) => {
    const [companies, setCompanies] = useState<Array<IOfferTypeCompany>>([]);
    const [selectedCompanyId, setSelectedCompanyId] = useState<string>();
    const [bestOfferId, setBestOfferId] = useState<string>();

    const template = useMemo(() => {
      return offerType;
    }, [offerType]);

    useEffect(() => {
      if (template?.ins_companies) {
        setCompanies(template.ins_companies);
        setBestOfferId(template?.bestOfferId);
      }
    }, [template?.ins_companies]);

    useEffect(() => {
      onUpdatedV2?.({ companies, bestOfferId });
    }, [companies, bestOfferId]);

    const companyListOptions = useMemo(() => {
      return companyStore.companies?.map((item) => ({
        label: item?.name,
        value: item?.id
      }));
    }, [companyStore.companies, companies]);

    const addCompanyToList = () => {
      let findCompany:
        | ({ id: number; name: string; logo: string } & IOfferTypeCompany)
        | any = companyStore.companies?.find((c) => c.id === selectedCompanyId);
      if (findCompany) {
        findCompany = {
          id: findCompany.id,
          name: findCompany.name,
          logo: findCompany.logo
        };
        setCompanies((prev) => [
          ...prev,
          {
            ...findCompany,
            uid: generateUid(),
            cashPrice: undefined,
            installmentPrice: undefined,
            installmentDue: undefined,
            packageName: undefined
          }
        ]);
        setSelectedCompanyId(undefined);
      }
    };

    const updateCompanies = (payload: {
      company: IOfferTypeCompany;
      field: string;
      value: string | number | undefined;
    }) => {
      const { company, value, field } = payload;
      setCompanies((prev) => {
        const findDataIndex = prev?.findIndex((p) => p.uid === company.uid);
        const updatedDataForCompany = [...(prev || [])];
        if (findDataIndex > -1) {
          updatedDataForCompany[findDataIndex] = {
            ...updatedDataForCompany[findDataIndex],
            [field]: value
          };
        }
        return updatedDataForCompany;
      });
    };

    const removeCompany = useCallback(
      (companyId: string) => {
        const newCompanyList = companies?.filter((c) => c.uid !== companyId);
        setCompanies(newCompanyList);
      },
      [companies]
    );

    const companyList = useMemo(() => {
      return sortBy(companies, 'name');
    }, [companies]);

    const companyListTableCols: ColumnsType<IOfferTypeCompany> = [
      {
        title: 'Sigorta Şirketi',
        key: 'name',
        dataIndex: 'name',
        render: (_, company) => (
          <div className={styles.row}>
            <img src={company.logo} width={100} />
            <span>{company.name}</span>
          </div>
        )
      },
      {
        title: 'Paket Adı',
        key: 'packageName',
        render: (_, company, index) => {
          return (
            <Input
              key={index}
              height={32}
              id={'packageName'}
              disabled={isOfferHistory}
              value={company?.packageName || ''}
              onChange={(e) =>
                updateCompanies({
                  company,
                  value: e?.currentTarget?.value,
                  field: 'packageName'
                })
              }
            />
          );
        }
      },
      {
        title: 'Peşin Fiyat',
        key: 'cashPrice',
        render: (_, company, index) => (
          <NumericFormat
            key={index}
            prefix={`₺ `}
            decimalScale={2}
            customInput={Input}
            decimalSeparator={','}
            thousandSeparator={'.'}
            disabled={isOfferHistory}
            value={company?.cashPrice || ''}
            onValueChange={({ floatValue }) =>
              updateCompanies({
                company,
                value: floatValue,
                field: 'cashPrice'
              })
            }
          />
        )
      },
      {
        title: 'Taksitli Fiyat',
        key: 'installmentPrice',
        render: (_, company, index) => (
          <NumericFormat
            key={index}
            prefix={`₺ `}
            decimalScale={2}
            customInput={Input}
            decimalSeparator={','}
            thousandSeparator={'.'}
            disabled={isOfferHistory}
            value={company?.installmentPrice || ''}
            onValueChange={({ floatValue }) =>
              updateCompanies({
                company,
                value: floatValue,
                field: 'installmentPrice'
              })
            }
          />
        )
      },
      {
        title: 'Taksit Sayısı',
        key: 'installmentDue',
        render: (_, company, index) => (
          <InputNumber
            min={1}
            max={12}
            key={index}
            disabled={isOfferHistory}
            value={company?.installmentDue || ''}
            onChange={(value: any) =>
              updateCompanies({
                company,
                value,
                field: 'installmentDue'
              })
            }
          />
        )
      },
      {
        title: '',
        key: 'remove',
        dataIndex: 'remove',
        render: (_, company) =>
          !isOfferHistory ? (
            <MinusCircleOutlined
              onClick={() => removeCompany(company.uid)}
              className={styles.minusIcon}
            />
          ) : null
      }
    ];

    return (
      <>
        <Table
          pagination={{
            pageSize: pageSize || 50
          }}
          bordered
          rowSelection={{
            type: 'checkbox',
            hideSelectAll: true,
            columnTitle: 'En iyi',
            // selectedRowKeys: [bestOfferId as Key],
            selectedRowKeys: [bestOfferId as Key],
            onSelect: (company, selected) =>
              !isOfferHistory
                ? setBestOfferId(selected ? company.uid : undefined)
                : undefined
          }}
          dataSource={companyList}
          rowKey={(item) => item.uid}
          columns={companyListTableCols}
          footer={() =>
            !isOfferHistory && (
              <Row gutter={16}>
                <Col flex={'auto'}>
                  <SWSelectBox
                    onChange={(value) => setSelectedCompanyId(value.join(''))}
                    value={selectedCompanyId ? [selectedCompanyId] : undefined}
                    options={companyListOptions || []}
                    placeholder={'Eklemek istediğiniz firmayı seçiniz'}
                  />
                </Col>
                <Col>
                  <SWButton
                    disabled={!selectedCompanyId}
                    onClick={addCompanyToList}
                    content={'Ekle'}
                  />
                </Col>
              </Row>
            )
          }
        />
      </>
    );
  }
);

export default SWTemplateCompanyTable;
