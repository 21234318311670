import React, { useMemo, useState } from 'react';

import { Col, Divider, Modal, Row, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { observer } from 'mobx-react';
import { sortBy } from 'lodash';

import { CreateUserRequestDto } from '../../../HttpService/DTOs/requestDtos';
import { IUser } from '../../../Types/Interfaces/UserInterfaces';
import { SWUserForm } from '../../../Components/Composite';
import { SWButton } from '../../../Components/UI';
import { userStore } from '../../../GlobalStore';
import styles from './index.module.scss';

const UserManagementPage = observer(() => {
  const [createFormValid, setCreateFormValid] = useState<boolean>(false);
  const [updateFormValid, setUpdateFormValid] = useState<boolean>(false);

  const [isCreateFormVisible, setIsCreateFormVisible] =
    useState<boolean>(false);

  const [inputs, setInputs] = useState<CreateUserRequestDto>();
  const [selectedUser, setSelectedUser] = useState<IUser>();

  const users = useMemo(() => {
    return sortBy(userStore.users, (u) => `${u.name} ${u.surname}`);
  }, [userStore.users]);

  const userTableCols: ColumnsType<IUser> = [
    {
      title: 'Ad Soyad',
      key: 'nameSurname',
      dataIndex: 'nameSurname',
      render: (_, user) => `${user.name} ${user.surname}`,
      sorter: (a, b) =>
        `${a.name} ${a.surname}`.length - `${b.name} ${b.surname}`.length
    },
    {
      title: 'T.C. Kimlik No',
      key: 'tc',
      dataIndex: 'tc',
      render: (_, user) => user.tc || '-'
    },
    {
      title: 'Şube',
      key: 'bagli_sube',
      dataIndex: 'bagli_sube',
      render: (_, user) => user.bagli_sube_name || '-'
    },
    {
      title: 'Pozisyon',
      key: 'position',
      dataIndex: 'position',
      render: (_, user) => user.position || '-'
    },
    {
      title: 'E-posta Adresi',
      key: 'email',
      dataIndex: 'email',
      sorter: (a, b) => a.email.length - b.email.length,
      render: (_, user) => (
        <a className={styles.link} href={`mailto:${user.email}`}>
          {user.email}
        </a>
      )
    },

    {
      title: 'İşlem',
      key: 'actions',
      dataIndex: 'actions',
      align: 'right',
      render: (_, user) => (
        <Row gutter={10} justify="end">
          <Col>
            <SWButton
              outlined
              type={'secondary'}
              content={'Düzenle'}
              onClick={() => {
                setSelectedUser(user);
                setInputs({
                  name: user.name,
                  surname: user.surname,
                  email: user.email,
                  bagli_sube_id: user.bagli_sube_id,
                  position: user.position,
                  tc: user.tc,
                  hashed_password: ''
                });
              }}
            />
          </Col>
          <Col>
            <SWButton
              outlined
              content={'Sil'}
              onClick={() => userStore.deleteUser(user.id)}
            />
          </Col>
        </Row>
      )
    }
  ];

  const onClose = () => {
    setInputs(undefined);
    if (selectedUser) setSelectedUser(undefined);
    if (isCreateFormVisible) setIsCreateFormVisible(false);
  };

  return (
    <>
      <Divider>
        <h2>Kullanıcı Yönetimi</h2>
      </Divider>

      <div className={styles.createBtnContainer}>
        <SWButton
          icon={<PlusOutlined />}
          content={'Yeni Kullanıcı'}
          onClick={() => setIsCreateFormVisible(true)}
        />
      </div>

      <Table
        dataSource={users}
        columns={userTableCols}
        rowKey={(user) => user.id}
        loading={userStore.usersLoading}
      />

      <Modal
        destroyOnClose
        width={'50%'}
        okText={'Kaydet'}
        okButtonProps={{
          disabled: !updateFormValid
        }}
        cancelText={'İptal'}
        open={typeof selectedUser !== 'undefined'}
        onOk={() => {
          if (!inputs) return;
          userStore.updateUser({
            id: selectedUser?.id || -1,
            ...inputs
          });
          onClose();
        }}
        onCancel={onClose}
        title={`Kullanıcı Düzenle - ${selectedUser?.email}`}>
        <Row gutter={[16, 16]} style={{ paddingTop: 24, paddingBottom: 24 }}>
          <SWUserForm
            inputs={inputs}
            handleInput={setInputs}
            handleValid={setUpdateFormValid}
          />
        </Row>
      </Modal>

      <Modal
        destroyOnClose
        width={'50%'}
        okText={'Kaydet'}
        okButtonProps={{
          disabled: !createFormValid
        }}
        cancelText={'İptal'}
        open={isCreateFormVisible}
        onOk={() => {
          if (!inputs) return;
          userStore.createUser(inputs, { onSuccess: onClose });
        }}
        onCancel={onClose}
        title={`Kullanıcı Oluştur`}>
        <Row gutter={[16, 16]} style={{ paddingTop: 24, paddingBottom: 24 }}>
          <SWUserForm
            isCreateForm
            inputs={inputs}
            handleInput={setInputs}
            handleValid={setCreateFormValid}
          />
        </Row>
      </Modal>
    </>
  );
});

export default UserManagementPage;
