import { DefaultOptionType } from 'rc-select/es/Select';

export const MockDataTaxOffices: Array<DefaultOptionType> = [
  {
    label: 'Ankara',
    value: 'Ankara'
  },
  {
    label: 'İstanbul',
    value: 'İstanbul'
  },
  {
    label: 'İzmir',
    value: 'İzmir'
  }
];

export const MockDataBranches: Array<DefaultOptionType> = [
  {
    label: 'Merkez',
    value: 'Merkez'
  }
];

export const MockPositions: Array<DefaultOptionType> = [
  {
    label: 'Satış Uzmanı',
    value: 'Satış Uzmanı'
  }
];
