import styles from './index.module.scss';
import { IInputProp } from './types';
import { NumericFormat, PatternFormat } from 'react-number-format';
import cn from 'classnames';

const SWInput = ({
  id,
  style,
  value,
  label,
  prefix,
  suffix,
  onBlur,
  format,
  onFocus,
  onChange,
  minLength,
  maxLength,
  height = 45,
  placeholder,
  autoComplete,
  labelBgColor,
  type = 'text',
  editable = true,
  displayType = 'input'
}: IInputProp) => {
  return (
    <div
      style={{
        height: displayType === 'text' ? undefined : `${height / 10}rem`,
        ...style?.border
      }}
      className={cn(styles.container, {
        [styles.displayText]: displayType === 'text',
        [styles.editable]: editable
      })}>
      {label && (
        <label
          htmlFor={id}
          className={styles.label}
          style={{
            background: labelBgColor,
            fontSize: `${height * 0.03111}rem`,
            top: `${-(height * 0.02777)}rem`
          }}>
          {label}
        </label>
      )}

      {format && (
        <PatternFormat
          value={value}
          format={format}
          prefix={prefix}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={!editable}
          maxLength={maxLength}
          minLength={minLength}
          className={styles.input}
          placeholder={placeholder}
          displayType={displayType}
          contentEditable={editable}
          autoComplete={autoComplete}
          onValueChange={(values) => onChange(values.value)}
          style={{
            backgroundColor: labelBgColor,
            // fontSize: style?.fontSize
            //   ? style.fontSize
            //   : displayType === 'text'
            //   ? `${height * 0.03111}rem`
            //   : `${height * 0.03555}rem`,
            fontSize: style?.fontSize,
            color: style?.color,
            padding: displayType === 'text' || label ? undefined : 10,
            fontWeight: style?.fontWeight,
            textAlign: style?.textAlign
          }}
        />
      )}

      {!format && type === 'money' && (
        <NumericFormat
          value={value}
          prefix={prefix}
          onBlur={onBlur}
          decimalScale={2}
          onFocus={onFocus}
          fixedDecimalScale
          disabled={!editable}
          maxLength={maxLength}
          minLength={minLength}
          decimalSeparator={','}
          suffix={suffix || ` ₺`}
          thousandSeparator={'.'}
          className={styles.input}
          displayType={displayType}
          placeholder={placeholder}
          contentEditable={editable}
          autoComplete={autoComplete}
          onValueChange={(values) => onChange(values.value)}
          style={{
            backgroundColor: labelBgColor,
            fontSize: style?.fontSize
              ? style.fontSize
              : displayType === 'text'
              ? `${height * 0.03111}rem`
              : `${height * 0.03555}rem`,
            color: style?.color,
            padding: displayType === 'text' || label ? undefined : 10,
            fontWeight: style?.fontWeight,
            textAlign: style?.textAlign
          }}
        />
      )}

      {!format && type === 'text' && (
        <input
          id={id}
          type="text"
          value={value}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={!editable}
          maxLength={maxLength}
          minLength={minLength}
          className={styles.input}
          placeholder={placeholder}
          contentEditable={editable}
          autoComplete={autoComplete}
          onChange={(e) => onChange(e?.currentTarget?.value)}
          style={{
            backgroundColor: labelBgColor,
            // fontSize: style?.fontSize
            //   ? style.fontSize
            //   : displayType === 'text'
            //   ? `${height * 0.03111}rem`
            //   : `${height * 0.03555}rem`,
            color: style?.color,
            padding: displayType === 'text' || label ? undefined : 10,
            fontWeight: style?.fontWeight,
            textAlign: style?.textAlign
          }}
        />
      )}
    </div>
  );
};

export default SWInput;
