import React, { useEffect, useMemo } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, DatePicker } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';

import {
  IndividualCustomerFormProp,
  IndividualCustomerInputType
} from './types';
import { createOrUpdateIndividualCustomerFormRules } from '../../../FormValidation/Rules/createOrUpdateIndividualCustomerFormRules';
import { CustomerType } from '../../../Types/Enums/CustomerEnums';
import { MockDataBranches } from '../../../Constants/TaxConstants';
import { SWFormErrorMessage } from '../../UI/SWFormErrorMessage';
import styles from './index.module.scss';
import { SWInput } from '../../UI';

const inputHeight = 35;
const SWIndividualCustomerForm = ({
  inputs,
  handleInput,
  handleValid
}: IndividualCustomerFormProp) => {
  const {
    control,
    setValue,
    getValues,
    trigger,
    formState: { errors, isValid }
  } = useForm({
    mode: 'all',
    resolver: yupResolver(createOrUpdateIndividualCustomerFormRules),
    defaultValues: {
      customerType: CustomerType.Individual
    }
  });

  useEffect(() => {
    handleValid(isValid);
  }, [isValid]);

  useEffect(() => {
    if (inputs) {
      setValue('customerType', CustomerType.Individual);
      setValue('isim_soyisim', inputs?.isim_soyisim || '');
      setValue('tc', inputs?.tc || '');
      setValue('eposta', inputs?.eposta || '');
      setValue('telefon', inputs?.telefon || '');
      setValue('dogum_gunu', inputs?.dogum_gunu || '');
    }
  }, [inputs]);

  const updateInput = (
    field: keyof IndividualCustomerInputType,
    value: string | undefined
  ) => {
    // @ts-ignore
    setValue(field, value);
    trigger(field as any);
    handleInput(getValues() as any);
  };

  const branches = useMemo(() => MockDataBranches, []);

  return (
    <>
      <Col span={12}>
        <div className={cn(styles.label, styles.required)}>Ad Soyad</div>
        <Controller
          control={control}
          name={'isim_soyisim'}
          render={({ field }) => (
            <>
              <SWInput
                {...field}
                id={'nameSurname'}
                height={inputHeight}
                style={{ border: { borderWidth: 1 } }}
                onChange={(value) => updateInput('isim_soyisim', value)}
              />
              <SWFormErrorMessage message={errors?.isim_soyisim?.message} />
            </>
          )}
        />
      </Col>
      <Col span={12}>
        <div className={cn(styles.label, styles.required)}>T.C. Kimlik No</div>
        <Controller
          control={control}
          name={'tc'}
          render={({ field }) => (
            <>
              <SWInput
                id={'tc'}
                {...field}
                height={inputHeight}
                format="###########"
                style={{ border: { borderWidth: 1 } }}
                onChange={(value) => updateInput('tc', value)}
              />
              <SWFormErrorMessage message={errors?.tc?.message} />
            </>
          )}
        />
      </Col>
      <Col span={12}>
        <div className={cn(styles.label, styles.required)}>Doğum Tarihi</div>
        <Controller
          control={control}
          name={'dogum_gunu'}
          render={({ field }) => (
            <>
              <DatePicker
                {...field}
                placeholder=""
                id={'dogum_gunu'}
                format={'DD.MM.YYYY'}
                onChange={(value) => updateInput('dogum_gunu', value?.toJSON())}
                value={
                  inputs?.dogum_gunu ? dayjs(inputs?.dogum_gunu) : undefined
                }
              />
              <SWFormErrorMessage message={errors?.dogum_gunu?.message} />
            </>
          )}
        />
      </Col>
      <Col span={12}>
        <div className={cn(styles.label, styles.required)}>E-posta</div>
        <Controller
          control={control}
          name={'eposta'}
          render={({ field }) => (
            <>
              <SWInput
                {...field}
                id={'eposta'}
                height={inputHeight}
                style={{ border: { borderWidth: 1 } }}
                onChange={(value) => updateInput('eposta', value)}
              />
              <SWFormErrorMessage message={errors?.eposta?.message} />
            </>
          )}
        />
      </Col>
      <Col span={12}>
        <div className={cn(styles.label, styles.required)}>GSM No</div>
        <Controller
          control={control}
          name={'telefon'}
          render={({ field }) => (
            <>
              <SWInput
                {...field}
                id={'telefon'}
                height={inputHeight}
                format={'0 (###) ### ## ##'}
                style={{ border: { borderWidth: 1 } }}
                onChange={(value) => updateInput('telefon', value)}
              />
              <SWFormErrorMessage message={errors?.telefon?.message} />
            </>
          )}
        />
      </Col>
    </>
  );
};

export default SWIndividualCustomerForm;
