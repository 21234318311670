import { useEffect, useMemo, useState } from 'react';

import datePickerLocale from 'antd/es/date-picker/locale/tr_TR';
import { Col, DatePicker, Divider, Drawer, Row } from 'antd';
import { PatternFormat } from 'react-number-format';
import { toast } from 'react-hot-toast';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';

import {
  SWCustomerListModal,
  SWTemplateCompanyTable,
  SWTemplateHeaderFields,
  SWTemplateSpecialFields
} from '../index';
import {
  IOfferTypeCompany,
  IOfferTypeHeader,
  IOfferTypeSpecialField
} from '../../../Types/Interfaces/OfferTypeInterfaces';
import { customerStore, offerStore, routerStore } from '../../../GlobalStore';
import styles from '../../../Pages/auth/offers/create/index.module.scss';
import { ICustomer } from '../../../Types/Interfaces/CustomerInterfaces';
import { CustomerType } from '../../../Types/Enums/CustomerEnums';
import { mapCompanyLogo } from '../../../Helpers/mapCompanyLogo';
import { useHttpService } from '../../../Hooks/useHttpService';
import { OfferUIProps } from '../../../Pages/offer-ui/types';
import { PageLink } from '../../../Constants/PageLink';
import { IEditOfferTemplateDrawerProp } from './types';
import { SWButton, SWRadio } from '../../UI';
import { OfferHistorySortTypeEnums } from 'src/Types/Enums/OfferHistorySortTypeEnums';
import { OfferHistorySortOrderEnums } from 'src/Types/Enums/OfferHistorySortOrderEnums';

const { RangePicker } = DatePicker;

const SWEditOfferTemplateDrawer = observer(
  ({
    refNo,
    title,
    visible,
    creator,
    onClose,
    offerType,
    customerId,
    isCopyOffer,
    isOfferHistory
  }: IEditOfferTemplateDrawerProp) => {
    const [createOffer] = useHttpService('CreateOffer');

    const [headerInputs, setHeaderInputs] = useState<Array<IOfferTypeHeader>>(
      []
    );
    const [specialInputs, setSpecialInputs] = useState<
      Array<IOfferTypeSpecialField>
    >([]);

    const [isCustomerListVisible, setIsCustomerListVisible] =
      useState<boolean>(false);
    const [selectedCustomer, setSelectedCustomer] = useState<ICustomer>();
    const [companies, setCompanies] = useState<Array<IOfferTypeCompany>>([]);
    const [bestOfferId, setBestOfferId] = useState<string>('');
    const [fileType, setFileType] = useState<'pdf' | 'jpg'>();
    const [dates, setDates] = useState<{
      offerDate: string;
      validityDate: string;
    }>({ offerDate: '', validityDate: '' });

    useEffect(() => {
      return () => {
        setFileType(undefined);
        setSelectedCustomer(undefined);
      };
    }, [visible]);

    useEffect(() => {
      if (
        isCopyOffer &&
        typeof customerId === 'string' &&
        customerStore.customers?.length
      ) {
        const findCustomer = customerStore.customers.find(
          (c) => c.id === customerId
        );
        if (findCustomer) {
          setSelectedCustomer(findCustomer);
        }
      }
    }, [customerStore.customers, customerId, isCopyOffer]);

    const submitForm = () => {
      if (!isCopyOffer && offerType) {
        offerStore.updateOfferTemplate(
          {
            bestOfferId,
            pay_type: '',
            id: offerType.id,
            specials: specialInputs || [],
            type_name: offerType.type_name,
            ins_companies: companies || [],
            template_headers: headerInputs
          },
          { onSuccess: () => onClose() }
        );
      } else if (isCopyOffer && offerType) {
        if (!selectedCustomer) return;
        const payload = {
          customerId: selectedCustomer?.id,
          offer_inside: JSON.stringify({
            ins_companies: companies,
            template_headers: headerInputs,
            specials: specialInputs,
            bestOfferId,
            pay_type: '',
            offerDate: dates.offerDate,
            offerValidityDate: dates.validityDate,
            type_name: offerType.type_name,
            templateId: offerType.templateId || -1,
            user_info: selectedCustomer
          })
        };

        createOffer(payload, {
          onSuccess: (data) => {
            toast.success('Teklif kopyalandı');
            offerStore.fillOfferHistory(1, 10, OfferHistorySortTypeEnums.USER, OfferHistorySortOrderEnums.ASC);
            onClose();
          },
          onError: (err) => console.log('err', err)
        });
      }
    };

    const redirectDownloadPage = () => {
      let data: OfferUIProps = {
        // @ts-ignore
        customer: offerType?.user_info,
        // @ts-ignore
        refNo
      };
      if (offerType) {
        // @ts-ignore
        data = {
          ...data,
          ...offerType,
          ins_companies: mapCompanyLogo(offerType.ins_companies)
        };
      }
      // @ts-ignore
      routerStore.setItem<OfferUIProps>('OfferDetail', { ...data, creator });
      let url = `${window.location.origin}${PageLink.OfferPdfDownload}`;
      if (fileType === 'jpg')
        url = `${window.location.origin}${PageLink.OfferJpegDownload}`;
      window.open(url, '_blank');
    };

    const isButtonEnable = useMemo(() => {
      return companies?.length || headerInputs?.length || specialInputs?.length;
    }, [headerInputs, companies, specialInputs]);

    const isCustomerIndividual = useMemo(() => {
      return selectedCustomer?.typeEnum === CustomerType.Individual;
    }, [selectedCustomer]);

    if (!visible) return null;

    return (
      <Drawer
        title={title}
        width={'70%'}
        destroyOnClose
        open={visible}
        extra={
          isCopyOffer && (
            <SWButton
              content="Müşteri Seç"
              onClick={() => setIsCustomerListVisible(true)}
            />
          )
        }
        onClose={onClose}>
        {isCopyOffer && (
          <SWCustomerListModal
            visible={isCustomerListVisible}
            onSelect={setSelectedCustomer}
            onClose={() => setIsCustomerListVisible(false)}
          />
        )}
        {isCopyOffer && selectedCustomer && (
          <>
            <Row gutter={24}>
              <Col span={6}>
                <div className={styles.customerInfo}>
                  <div className={styles.customerInfo__label}>
                    {isCustomerIndividual ? 'Ad Soyad' : 'Ünvan'}
                  </div>
                  <div className={styles.customerInfo__value}>
                    {selectedCustomer.isim_soyisim || selectedCustomer.unvan}
                  </div>
                </div>
              </Col>
              <Col span={6}>
                <div className={styles.customerInfo}>
                  <div className={styles.customerInfo__label}>
                    {isCustomerIndividual ? 'T.C. Kimlik No' : 'Vergi No'}
                  </div>
                  <div className={styles.customerInfo__value}>
                    {selectedCustomer.tc || selectedCustomer.vergi_no}
                  </div>
                </div>
              </Col>
              <Col span={6}>
                <div className={styles.customerInfo}>
                  <div className={styles.customerInfo__label}>
                    {isCustomerIndividual ? 'E-posta Adresi' : 'Vergi Dairesi'}
                  </div>
                  <div className={styles.customerInfo__value}>
                    {selectedCustomer.eposta || selectedCustomer.vergi_dairesi}
                  </div>
                </div>
              </Col>
              {isCustomerIndividual && (
                <Col span={6}>
                  <div className={styles.customerInfo}>
                    <div className={styles.customerInfo__label}>GSM No</div>
                    <PatternFormat
                      displayType="text"
                      format={'0 (###) ### ## ##'}
                      value={selectedCustomer.telefon}
                      className={styles.customerInfo__value}
                    />
                  </div>
                </Col>
              )}
            </Row>
            <br />
          </>
        )}
        {(isCopyOffer && selectedCustomer) || !isCopyOffer ? (
          <>
            <Row gutter={16}>
              <Col span={24}>
                <Divider orientation="left">Diğer Bilgiler</Divider>
              </Col>
              <Col span={24}>
                <SWTemplateHeaderFields
                  offerType={offerType}
                  isCopyOffer={isCopyOffer}
                  onUpdate={setHeaderInputs}
                  inputDisable={!isCopyOffer}
                  isOfferHistory={isOfferHistory}
                  onInputUpdated={(input) => {
                    if (!isCopyOffer) return null;
                    // @ts-ignore
                    setHeaderInputs((prev) => {
                      if (typeof input.value === 'undefined')
                        return prev.filter((p) => p.label !== input.label);
                      return [
                        ...prev.filter((p) => p.label !== input?.label),
                        input
                      ];
                    });
                  }}
                />
              </Col>
            </Row>
            <br />
            <Row gutter={16}>
              <Col span={24}>
                <Divider orientation="left">Şirket Teklifleri</Divider>
              </Col>
              <Col span={24}>
                <SWTemplateCompanyTable
                  offerType={offerType}
                  isOfferHistory={isOfferHistory}
                  onUpdatedV2={({
                    companies,
                    bestOfferId: callbackBestOfferId
                  }) => {
                    setCompanies(companies);
                    setBestOfferId(callbackBestOfferId || '');
                  }}
                />
              </Col>
            </Row>
            <br />
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Divider orientation="left">En İyi Teklif İçeriği</Divider>
              </Col>
              <Col span={24}>
                <SWTemplateSpecialFields
                  offerType={offerType}
                  inputDisable={!isCopyOffer}
                  onUpdated={setSpecialInputs}
                  isCopyOffer={isCopyOffer}
                  isOfferHistory={isOfferHistory}
                  onInputUpdated={(input) => {
                    if (!isCopyOffer) return null;
                    // @ts-ignore
                    setSpecialInputs((prev) => {
                      if (typeof input.value === 'undefined')
                        return prev.filter((p) => p.label !== input.label);
                      return [
                        ...prev.filter((p) => p.label !== input?.label),
                        input
                      ];
                    });
                  }}
                />
              </Col>
            </Row>
            <br />
            <br />
            {isOfferHistory && (
              <Col span={24}>
                <Divider orientation="left">Teklif İndir</Divider>
              </Col>
            )}
            <Row gutter={[24, 18]}>
              {isOfferHistory && (
                <>
                  <Col span={24}>
                    <SWRadio
                      label={'PDF'}
                      checked={fileType === 'pdf'}
                      onCheck={() => setFileType('pdf')}
                    />
                    <SWRadio
                      label={'JPEG'}
                      checked={fileType === 'jpg'}
                      onCheck={() => setFileType('jpg')}
                    />
                  </Col>
                  <Col span={24}>
                    <SWButton
                      content={'İndir'}
                      disabled={!fileType}
                      onClick={redirectDownloadPage}
                    />
                  </Col>
                </>
              )}
              {isCopyOffer && (
                <Col flex={'50%'}>
                  <div className={styles.dateContainer}>
                    <div className={styles.labelContainer}>
                      <div className={styles.label}>
                        Teklif Tarihi <span className={styles.required}>*</span>
                      </div>
                      <div className={styles.label}>
                        Geçerlilik Tarihi{' '}
                        <span className={styles.required}>*</span>
                      </div>
                    </div>
                    <RangePicker
                      locale={{
                        ...datePickerLocale,
                        lang: {
                          ...datePickerLocale.lang,
                          rangePlaceholder: [
                            'Teklif Tarihi',
                            'Geçerlilik Tarihi'
                          ]
                        }
                      }}
                      presets={[
                        {
                          label: '1 Hafta Sonrası',
                          value: [dayjs(), dayjs().add(1, 'week')]
                        },
                        {
                          label: '1 Ay Sonrası',
                          value: [dayjs(), dayjs().add(1, 'month')]
                        }
                      ]}
                      onChange={(dates) => {
                        if (dates?.length === 2 && dates[0] && dates[1]) {
                          const offerDate = dates[0];
                          const validityDate = dates[1];
                          setDates({
                            offerDate: offerDate.format('DD.MM.YYYY'),
                            validityDate: validityDate.format('DD.MM.YYYY')
                          });
                        }
                      }}
                    />
                  </div>
                </Col>
              )}
              {!isOfferHistory && (
                <Col span={24}>
                  <SWButton
                    content={'Kaydet'}
                    onClick={submitForm}
                    disabled={!isButtonEnable}
                  />
                </Col>
              )}
            </Row>
          </>
        ) : (
          <h4 className={styles.textCenter}>
            Devam etmek için müşteri seçmelisiniz
          </h4>
        )}
      </Drawer>
    );
  }
);

export default SWEditOfferTemplateDrawer;
