import React from 'react';

import { Button } from 'antd';
import cn from 'classnames';

import styles from './index.module.scss';
import { IButtonProp } from './types';

const SWButton = ({
  size,
  icon,
  shape,
  onClick,
  loading,
  content,
  disabled,
  outlined,
  htmlType,
  className,
  type = 'primary'
}: IButtonProp) => {
  return (
    <Button
      icon={icon}
      size={size}
      shape={shape}
      loading={loading}
      htmlType={htmlType}
      onClick={() => (disabled ? null : onClick && onClick())}
      className={cn(
        styles.button,
        styles[type],
        outlined && styles.outlined,
        disabled && styles.disabled,
        loading && styles.loading,
        className
      )}>
      {content}
    </Button>
  );
};

export default SWButton;
