import React, { useMemo, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, Input, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import { createOfferTemplateFormRules } from '../../../../FormValidation/Rules/createOfferTemplateFormRules';
import { IOfferType } from '../../../../Types/Interfaces/OfferTypeInterfaces';
import { SWEditOfferTemplateDrawer } from '../../../../Components/Composite';
import { authStore, offerStore } from '../../../../GlobalStore';
import { RoleEnum } from '../../../../Types/Enums/RoleEnum';
import { SWButton } from '../../../../Components/UI';
import styles from './index.module.scss';

const OfferTemplatesPage = observer(() => {
  const [selectedOfferType, setSelectedOfferType] = useState<IOfferType>();

  const {
    reset,
    control,
    getValues,
    formState: { isValid }
  } = useForm({
    resolver: yupResolver(createOfferTemplateFormRules)
  });

  const offerTemplates = useMemo(() => {
    return offerStore.offerTemplates;
  }, [toJS(offerStore.offerTemplates)]);

  const isStaff = useMemo(() => {
    return authStore.roles.includes(RoleEnum.Staff);
  }, [authStore.roles]);

  const createTemplate = () => {
    offerStore.createOfferTemplate({ name: getValues('name') });
    reset();
  };

  const refreshDrawer = () => {
    setSelectedOfferType(undefined);
  };

  const templatesTableColumns: ColumnsType<IOfferType> = useMemo(() => {
    return [
      {
        title: 'Teklif Tipi',
        dataIndex: 'type_name',
        key: 'type_name',
        width: '100%',
        sorter: (a, b) => a.type_name.length - b.type_name.length
      },
      {
        title: 'Aksiyon',
        dataIndex: 'actions',
        key: 'actions',
        render: (_, offer) => (
          <div className={styles.actionsContainer}>
            {isStaff || authStore.userInfo?.email === offer.creator ? (
              <SWButton
                outlined
                type={'secondary'}
                content={'Düzenle'}
                onClick={() => setSelectedOfferType(offer)}
              />
            ) : null}
            <SWButton
              outlined
              content={'Sil'}
              onClick={() => offerStore.deleteOfferTemplate(offer.id)}
            />
          </div>
        )
      }
    ];
  }, []);

  return (
    <>
      <Divider>
        <h2>Teklif Şablonları ({offerTemplates?.length || 0} adet)</h2>
      </Divider>
      <Table
        bordered
        rowKey={(item) => `${item.id}${item.type_name}`}
        dataSource={offerTemplates}
        columns={templatesTableColumns}
        loading={offerStore.offerTemplatesLoading}
        footer={() => (
          <div className={styles.actionsContainer}>
            <Controller
              name={'name'}
              control={control}
              render={({ field }) => <Input {...field} placeholder="Teklif" />}
            />
            <SWButton
              content="Ekle"
              disabled={!isValid}
              onClick={createTemplate}
            />
          </div>
        )}
      />

      <SWEditOfferTemplateDrawer
        onClose={refreshDrawer}
        offerType={selectedOfferType}
        visible={typeof selectedOfferType !== 'undefined'}
        title={`Şablon Düzenle - ${selectedOfferType?.type_name}`}
      />
    </>
  );
});

export default OfferTemplatesPage;
