import { useEffect, useState } from 'react';

import { BaseResponseDto } from '../HttpService/DTOs/responseDtos';
import { serviceErrorHelper } from '../Helpers/serviceErrorHelper';
import { BaseRequestDto } from '../HttpService/DTOs/requestDtos';
import { ResponseError } from '../HttpService/types';
import HttpService from '../HttpService';

type RequestCallback<Response> = {
  onSuccess?: (data: Response) => void;
  onError?: (err: ResponseError) => void;
  onFinally?: () => void;
};

type ReturnData<
  Params extends BaseRequestDto | undefined,
  Response extends BaseResponseDto
> = [
  request: (params: Params, callbackFunc?: RequestCallback<Response>) => void,
  data: Response | undefined,
  error: ResponseError | undefined,
  loading: boolean
];

export function useHttpService<
  Params extends BaseRequestDto | undefined,
  Response extends BaseResponseDto
>(serviceName: keyof typeof HttpService): ReturnData<Params, Response> {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ResponseError>();
  const [data, setData] = useState<Response>();

  const request = (
    params: Params,
    callbackFunc?: RequestCallback<Response>
  ): void => {
    setLoading(true);
    // @ts-ignore
    HttpService[serviceName](params)
      .then((res) => {
        // @ts-ignore
        setData(res?.data);
        // @ts-ignore
        callbackFunc?.onSuccess?.(res?.data);
      })
      .catch((e) => {
        const handledMessage = serviceErrorHelper(e);
        setError(handledMessage);
        callbackFunc?.onError?.(handledMessage);
      })
      .finally(() => {
        setLoading(false);
        setError(undefined);
        callbackFunc?.onFinally?.();
      });
  };

  useEffect(() => {
    return () => {
      if (data) setData(undefined);
      if (loading) setLoading(false);
      if (error) setError(undefined);
    };
  }, []);

  return [request, data, error, loading];
}
