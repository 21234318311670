import axios, { AxiosError } from 'axios';
import { toast } from 'react-hot-toast';

import {
  BaseResponseDto,
  CreateCompanyResponseDto,
  CreateOfferResponseDto,
  CreateOfferTypeResponseDto,
  CreateUserResponseDto,
  DownloadCustomerListResponseDto,
  GetBranchesResponseDto,
  GetCompaniesResponseDto,
  GetCustomersResponseDto,
  GetOfferTypeResponseDto,
  GetOfferTypesResponseDto,
  GetUsersResponseDto,
  LoginResponseDto
} from './DTOs/responseDtos';
import {
  CreateCompanyRequestDto,
  CreateCustomerRequestDto,
  CreateOfferRequestDto,
  CreateOfferTypeRequestDto,
  CreateUserRequestDto,
  DeleteCompanyRequestDto,
  DeleteCustomerRequestDto,
  DeleteUserRequestDto,
  DownloadCustomerListRequestDto,
  GetCustomersRequestDto,
  LoginRequestDto,
  OfferHistorySortType,
  OfferHistorySortOrder,
  UpdateCompanyRequestDto,
  UpdateCustomerRequestDto,
  UpdateOfferTypeRequestDto,
  UpdateUserRequestDto
} from './DTOs/requestDtos';
import { CustomerType } from '../Types/Enums/CustomerEnums';
import ServiceUrls, { HttpServiceBaseUrl } from './URL';
import { PageLink } from '../Constants/PageLink';
import { HttpServiceResponse } from './types';

const ServiceInstance = axios.create({
  baseURL: HttpServiceBaseUrl,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: false
});

export const setAuthTokenToHeader = (token: string | undefined) => {
  ServiceInstance.defaults.headers.common.Authorization = token;
};

export const getAuthTokenFromHeader = () => {
  return ServiceInstance.defaults.headers.common.Authorization || null;
};

export const deleteAuthTokenFromHeader = () => {
  delete ServiceInstance.defaults.headers.common.Authorization;
};

//TODO (ali berk) İstek esnasında header'a bilgi(Bearer token, etc.) eklenmek istenmesi durumunda request interceptor kullanılacak
ServiceInstance.interceptors.request.use(
  (config) => {
    const authToken =
      window.localStorage.getItem('@AuthToken') || getAuthTokenFromHeader();
    if (!config.headers?.Authorization) {
      config.headers['Authorization'] = authToken;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// TODO (ali berk) İsteğin döndüğü status'e göre alınmak istenen aksiyonlar(401 durumunda logout, etc.) burada handle edilecek
ServiceInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError<BaseResponseDto>) => {
    if (error?.response?.status === 401) {
      deleteAuthTokenFromHeader();
      window.localStorage.removeItem('@AuthToken');
      toast.error('Authentication failed');
      setTimeout(() => {
        window.location.href = `${window.location.origin}${PageLink.Login}`;
      }, 1000);
      return;
    }
    const errorDetail = error?.response?.data?.detail;
    if (Array.isArray(errorDetail)) {
      errorDetail?.map((err) => {
        toast.error(err.msg);
      });
    } else if (errorDetail) {
      toast.error(errorDetail);
    }

    if (!errorDetail) {
      toast.error(`Backend Error: ${error?.message}`);
    }

    return Promise.reject(error);
  }
);

const HttpService = {
  GetCompanies: (): HttpServiceResponse<GetCompaniesResponseDto> => {
    return ServiceInstance.get(ServiceUrls.Company.List);
  },
  CreateCompany: (
    payload: CreateCompanyRequestDto
  ): HttpServiceResponse<CreateCompanyResponseDto> => {
    return ServiceInstance.post(ServiceUrls.Company.Create, payload);
  },
  DeleteCompany: (payload: DeleteCompanyRequestDto) => {
    return ServiceInstance.delete(
      `${ServiceUrls.Company.Delete}/${payload.companyId}`
    );
  },
  UpdateCompany: (payload: UpdateCompanyRequestDto) => {
    const { id, ...data } = payload;
    return ServiceInstance.put(`${ServiceUrls.Company.Update}/${id}`, data);
  },
  Login: (payload: LoginRequestDto): HttpServiceResponse<LoginResponseDto> => {
    const formData = new FormData();
    formData.append('username', payload.username);
    formData.append('password', payload.password);
    return ServiceInstance.post(ServiceUrls.Login, payload, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    });
  },
  GetCurrentUserInfo: () => {
    return ServiceInstance.get(ServiceUrls.User.CurrentUserInfo);
  },
  GetUsers: (): HttpServiceResponse<GetUsersResponseDto> => {
    return ServiceInstance.post(ServiceUrls.User.List);
  },
  CreateUser: (
    payload: CreateUserRequestDto
  ): HttpServiceResponse<CreateUserResponseDto> => {
    const { bagli_sube_id, ...data } = payload;
    return ServiceInstance.post(
      `${ServiceUrls.User.Create}?bagli_sube_id=${bagli_sube_id}`,
      data
    );
  },
  DeleteUser: (payload: DeleteUserRequestDto) => {
    return ServiceInstance.delete(
      `${ServiceUrls.User.Delete}/${payload.userId}`
    );
  },
  UpdateUser: (payload: UpdateUserRequestDto) => {
    const { id, bagli_sube_id, ...data } = payload;
    return ServiceInstance.put(
      `${ServiceUrls.User.Update}/${id}?bagli_sube_id=${bagli_sube_id}`,
      data
    );
  },
  GetOfferTypes: (): HttpServiceResponse<GetOfferTypesResponseDto> => {
    return ServiceInstance.get(ServiceUrls.OfferType.List);
  },
  CreateOfferType: (
    payload: CreateOfferTypeRequestDto
  ): HttpServiceResponse<CreateOfferTypeResponseDto> => {
    return ServiceInstance.post(ServiceUrls.OfferType.Create, payload);
  },
  GetOfferType: (
    offerId: number
  ): HttpServiceResponse<GetOfferTypeResponseDto> => {
    return ServiceInstance.get(`${ServiceUrls.OfferType.Get}/${offerId}`);
  },
  DeleteOfferType: (offerId: number) => {
    return ServiceInstance.delete(`${ServiceUrls.OfferType.Delete}/${offerId}`);
  },
  UpdateOfferType: (payload: UpdateOfferTypeRequestDto) => {
    const { id, ...data } = payload;
    return ServiceInstance.put(`${ServiceUrls.OfferType.Update}/${id}`, data);
  },

  GetCustomers: (payload?: GetCustomersRequestDto) => {
    const data = payload || { Filter: [] };
    return ServiceInstance.post(ServiceUrls.Customer.General.List, data);
  },
  GetCustomer: (id: number) => {
    return ServiceInstance.get(`${ServiceUrls.Customer.Individual.Get}/${id}`);
  },
  GetCustomerByName: (name?: string) => {
    return ServiceInstance.get(ServiceUrls.Customer.Individual.Get, {
      params: { customer_name: name }
    });
  },
  DeleteCustomer: (payload: DeleteCustomerRequestDto) => {
    const { id, customerType } = payload;
    if (customerType === CustomerType.Corporate) {
      return ServiceInstance.delete(
        `${ServiceUrls.Customer.Corporate.Delete}/${id}`
      );
    }
    return ServiceInstance.delete(
      `${ServiceUrls.Customer.Individual.Delete}/${id}`
    );
  },
  UpdateCustomer: (payload: UpdateCustomerRequestDto) => {
    const { id, customerType, ...data } = payload;
    let serviceUrl = ServiceUrls.Customer.Individual.Update;
    if (customerType === CustomerType.Corporate) {
      serviceUrl = ServiceUrls.Customer.Corporate.Update;
    }
    return ServiceInstance.put(`${serviceUrl}/${id}`, data);
  },
  CreateCustomer: (
    payload: CreateCustomerRequestDto
  ): HttpServiceResponse<GetCustomersResponseDto> => {
    const { customerType, ...data } = payload;
    let serviceUrl = ServiceUrls.Customer.Individual.Create;
    if (customerType === CustomerType.Corporate) {
      serviceUrl = ServiceUrls.Customer.Corporate.Create;
    }
    return ServiceInstance.post(serviceUrl, data);
  },

  CreateOffer: (
    payload: CreateOfferRequestDto
  ): HttpServiceResponse<CreateOfferResponseDto> => {
    const { customerId, ...data } = payload;
    let config: any = undefined;
    if (customerId) config = { params: { customer_id: customerId } };
    return ServiceInstance.post(`${ServiceUrls.Offer.Create}`, data, config);
  },
  GetOffersHistory: (page: number = 1, pageSize: number = 10, sortBy: OfferHistorySortType = 'user', sortOrder: OfferHistorySortOrder = "asc", clientName: string = "", insuranceType: string = "", startDate: string = "", endDate: string = "") => {
    return ServiceInstance.get(ServiceUrls.Offer.List, {
      params: {
        page,
        page_size: pageSize,
        sort_field: sortBy,
        sort_order: sortOrder,
        client_name: !clientName.length ? undefined : clientName,
        insurance_type: !insuranceType.length ? undefined : insuranceType,
        start_date: !startDate.length ? undefined : startDate,
        end_date: !endDate.length ? undefined : endDate
      }
    });
  },
  DeleteOfferHistory: (offerId: number) => {
    return ServiceInstance.delete(`${ServiceUrls.Offer.Delete}/${offerId}`);
  },

  GetBranches: (): HttpServiceResponse<GetBranchesResponseDto> => {
    return ServiceInstance.get(ServiceUrls.Branch.List);
  },

  DownloadCustomerListAsExcel: (
    payload: DownloadCustomerListRequestDto
  ): HttpServiceResponse<DownloadCustomerListResponseDto> => {
    const { customerType } = payload;
    return ServiceInstance.get(ServiceUrls.Excel.Download, {
      params: {
        customer_type: customerType?.toLowerCase()
      }
    });
  }
};

export default HttpService;
