import { makeAutoObservable } from 'mobx';
import { toast } from 'react-hot-toast';
import { sortBy } from 'lodash';

import {
  mapOfferTypesToUse,
  mapOfferTypeToUse
} from '../../Helpers/offerTypeHelpers';
import {
  CreateOfferTypeRequestDto,
  OfferHistorySortOrder,
  OfferHistorySortType,
  UpdateOfferTypeRequestDto
} from '../../HttpService/DTOs/requestDtos';
import { IOfferHistory } from '../../Types/Interfaces/OfferInterfaces';
import { IOfferType } from '../../Types/Interfaces/OfferTypeInterfaces';
import { parseJson } from '../../Helpers/jsonHelpers';
import HttpService from '../../HttpService';
import { ActionCallback } from '../types';
import { ResponseError } from 'src/HttpService/types';

export default class OfferStore {
  constructor(private isAuthenticated: boolean) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.fillOfferTemplates();
  }

  offerTemplatesLoading: boolean = false;
  offerTemplates: Array<IOfferType> | undefined = [];

  offerHistory: IOfferHistory = {
    offers: [],
    current_page: 1,
    page_size: 10,
    total_pages: 1
  };

  offerHistoryLoading: boolean = false;

  templateLoading: boolean = false;

  template: IOfferType | undefined;

  async fillOfferHistory(page: number = 1, pageSize: number = 10, sortBy: OfferHistorySortType, sortOrder: OfferHistorySortOrder, clientName: string = "", insuranceType: string = "", startDate: string = "", endDate: string = "") {
    try {
      this.offerHistoryLoading = true;
      const { data } = await HttpService.GetOffersHistory(page, pageSize, sortBy, sortOrder, clientName, insuranceType, startDate, endDate)
      if (!data) return;
      this.offerHistory = {
        ...data,
        offers: data?.offers.map((item: any) => ({ ...item, offer_inside: parseJson(item?.offer_inside) }))
      };
    } catch (error) {
      console.log('fillOfferHistory', error);
    } finally {
      this.offerHistoryLoading = false;
    }
  }

  async deleteOfferHistory(offerId: number) {
    try {
      await HttpService.DeleteOfferHistory(offerId);
      this.offerHistory.offers = this.offerHistory.offers?.filter((i) => i.id !== offerId);
      toast.success('Teklif silindi');
    } catch (error) {
      console.log('Delete offer history', error);
    }
  }

  async fillOfferTemplates() {
    try {
      if (this.isAuthenticated) {
        this.offerTemplatesLoading = true;
        const { data } = await HttpService.GetOfferTypes();
        if (!data?.detail) this.offerTemplates = sortBy(mapOfferTypesToUse(data), 'type_name');
      }
    } catch (error) {
      console.log('fillOfferTemplates', error);
    } finally {
      this.offerTemplatesLoading = false;
    }
  }

  async fillTemplateByOfferId(offerId: number) {
    try {
      this.templateLoading = true;
      const { data } = await HttpService.GetOfferType(offerId);
      if (!data?.detail) this.template = mapOfferTypeToUse(data);
    } catch (error) {
      console.log('fillTemplateByOfferId', error);
    } finally {
      this.templateLoading = false;
    }
  }

  async createOfferTemplate(template: { name: string }) {
    try {
      const stringifyData = JSON.stringify([]);
      const payload: CreateOfferTypeRequestDto = {
        pay_type: '',
        specials: stringifyData,
        type_name: template.name,
        template_headers: stringifyData,
        ins_companies: JSON.stringify([]),
        bestOfferId: -1
      };
      const { data } = await HttpService.CreateOfferType(payload);

      if (!data?.detail) {
        this.offerTemplates = [...(this.offerTemplates || []), mapOfferTypeToUse(data)];
        toast.success('Teklif şablonu oluşturuldu');
      }
    } catch (error) {
      console.log('CreateOfferTemplate', error);
    }
  }

  async updateOfferTemplate(payload: IOfferType, callback?: ActionCallback<any>) {
    try {
      const request: UpdateOfferTypeRequestDto = {
        id: payload.id,
        type_name: payload.type_name,
        bestOfferId: payload.bestOfferId,
        specials: JSON.stringify(payload.specials),
        pay_type: JSON.stringify(payload.pay_type),
        ins_companies: JSON.stringify(payload.ins_companies),
        template_headers: JSON.stringify(payload.template_headers)
      };
      const { data } = await HttpService.UpdateOfferType(request);
      if (!data?.detail) {
        this.fillOfferTemplates();
        callback?.onSuccess?.();
        toast.success(`${payload.type_name} şablonu güncellendi`);
      }
    } catch (error) {
      console.log('updateOfferTemplate', error);
      callback?.onError?.(error as ResponseError);
    } finally {
      callback?.onFinally?.();
    }
  }

  async deleteOfferTemplate(templateId: number) {
    try {
      await HttpService.DeleteOfferType(templateId);
      this.offerTemplates = this.offerTemplates?.filter(({ id }) => id !== templateId);
      toast.success('Teklif şablonu silindi');
    } catch (error) {
      console.log('Delete offer template', error);
    }
  }
}
