import { useEffect, useMemo, useRef, useState } from 'react';

import { CheckOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { Col, Row, Spin } from 'antd';

import { IOfferTypeCompany } from '../../../Types/Interfaces/OfferTypeInterfaces';
import { routerStore } from '../../../GlobalStore';
import { SWInput } from '../../../Components/UI';
import styles from './index.module.scss';
import { OfferUIProps } from '../types';
import { sortBy } from 'lodash';

import cn from 'classnames';

const inputHeight = 35;
const firstPageCompanyLength = 5;
const secondPageCompanyLength = 30;
const OfferPdfUiPage = observer(() => {
  const domEl = useRef(null);

  const [offerDetailLoading, setOfferDetailLoading] = useState<boolean>(true);
  const [offerDetail, setOfferDetail] = useState<
    OfferUIProps & { fullName: string; email: string }
  >();

  useEffect(() => {
    routerStore.getItem<OfferUIProps>('OfferDetail', (data) => {
      if (data) {
        const splitCreator = data.creator?.split('_');
        if (splitCreator?.length && splitCreator.length >= 3) {
          const [name, surname, email] = splitCreator;
          setOfferDetail({ ...data, fullName: `${name} ${surname}`, email });
          setTimeout(() => {
            setOfferDetailLoading(false);
          }, 4000);
        }
      } else {
        window.close();
      }
    });
  }, []);

  useEffect(() => {
    if (!offerDetailLoading) {
      setTimeout(() => {
        downloadPDF();
      }, 500);
    }
  }, [offerDetailLoading, offerDetail]);

  const offerType = useMemo(
    () => offerDetail?.type_name,
    [offerDetail?.type_name]
  );

  const customer = useMemo(() => {
    return offerDetail?.customer;
  }, [offerDetail?.customer]);

  const bestCompany = useMemo(() => {
    return offerDetail?.ins_companies?.find(
      (c) => c.uid === offerDetail?.bestOfferId
    );
  }, [offerDetail?.bestOfferId, offerDetail?.ins_companies]);

  const otherCompanyList = useMemo(() => {
    let newList = sortBy(offerDetail?.ins_companies, 'cashPrice');
    if (offerDetail?.bestOfferId) {
      newList = newList.filter((i) => i.uid !== offerDetail.bestOfferId);
    }
    return newList;
  }, [offerDetail?.ins_companies, offerDetail?.bestOfferId]);

  const downloadPDF = async () => {
    if (!domEl?.current) return;

    window.document.title = offerDetail?.refNo || 'Siba_Sigorta';
    window.print();
    window.close();
  };

  const renderCompanyList = (data: {
    list: Array<IOfferTypeCompany>;
    hidePrint?: boolean;
    onlyPrint?: boolean;
    beforePageBreak?: boolean;
  }) => {
    const { list, hidePrint, onlyPrint, beforePageBreak } = data;
    if (!list?.length) return null;
    return (
      <div
        className={cn(styles.formContainer, {
          [styles.hidePrint]: hidePrint,
          [styles.onlyPrint]: onlyPrint,
          [styles.beforePageBreak]: beforePageBreak
        })}>
        <div className={styles.formItem}>
          <div className={styles.otherOffersTitleContainer}>
            <div className={styles.title}>
              {bestCompany?.name
                ? `Diğer ${offerType} Tekliflerimiz`
                : `${offerType} Tekliflerimiz`}
            </div>
          </div>
          <div className={styles.companyItemContainer}>
            {list?.map((company, index) => (
              <div className={styles.companyItem} key={index}>
                <div className={styles.companyInfo}>
                  <img
                    src={company.logo}
                    alt={company.name}
                    className={styles.companyLogo}
                  />
                  <div className={styles.companyName}>{company.name}</div>
                  {company?.packageName && (
                    <div className={styles.packageName}>
                      {company.packageName}
                    </div>
                  )}
                </div>
                <div className={styles.cashPrice}>
                  <SWInput
                    type={'money'}
                    editable={false}
                    id={'cashPrice'}
                    height={inputHeight}
                    label={'Peşin Fiyat'}
                    value={company.cashPrice || '-'}
                    onChange={(value) => null}
                  />
                </div>
                <div className={styles.installmentPrice}>
                  <SWInput
                    type={'money'}
                    editable={false}
                    height={inputHeight}
                    id={'installmentPrice'}
                    label={'Taksitli Fiyat'}
                    onChange={(value) => null}
                    value={company.installmentPrice || '-'}
                  />
                </div>
                <div className={styles.installmentPrice}>
                  <SWInput
                    type={'text'}
                    editable={false}
                    height={inputHeight}
                    id={'installmentDue'}
                    label={'Taksit sayısı'}
                    onChange={(value) => null}
                    value={company.installmentDue || '-'}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  if (!offerDetail) return null;

  return (
    <Spin spinning={offerDetailLoading}>
      <div className={styles.wrapper} ref={domEl}>
        <svg
          viewBox="0 0 595 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.5">
            <path
              d="M0 36.1111V0H422.965L455 9.27177L0 36.1111Z"
              fill="#D6232B"
            />
            <path d="M595 50L423 0H594.999L595 50Z" fill="#273C8F" />
          </g>
        </svg>
        <div className={styles.container}>
          <div className={styles.companyInfoContainer}>
            <div className={styles.logoDateContainer}>
              <img
                width={250}
                height={60}
                src="/assets/logo.png"
                alt="Siba Sigorta Logo"
              />
              <div className={styles.dateInfoContainer}>
                <div className={styles.dateInfo}>
                  <span className={styles.dateInfoLabel}>Teklif Tarihi:</span>
                  <span className={styles.info}>{offerDetail?.offerDate}</span>
                </div>
                <div className={styles.dateInfo}>
                  <span className={styles.dateInfoLabel}>
                    Geçerlilik Tarihi:
                  </span>
                  <span className={styles.info}>
                    {offerDetail?.offerValidityDate}
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.addressContainer}>
              <h2 className={styles.companyName}>
                <div className={styles.big}>SİBA SİGORTA</div>
                <div className={styles.small}>ARACILIK HİZ. LTD. ŞTİ.</div>
              </h2>
              <div className={styles.infoContainer}>
                <span className={styles.info} data-label="A">
                  Mustafa Kemal Mh. Dumlupınar Blv. No: 274/6 Mahall Ankara E
                  Blok D: 27, 06530 Çankaya/Ankara
                </span>
              </div>
              <div className={styles.infoContainer}>
                <span className={styles.info} data-label="T">
                  (+90) 312 4290100
                </span>
              </div>
              <div className={styles.infoContainer}>
                <span className={styles.info} data-label="E">
                  siba@sibasigorta.com.tr
                </span>
              </div>
            </div>
          </div>

          <div className={styles.customerInfoContainer}>
            <h3 className={styles.customerName}>
              Sn. {customer?.isim_soyisim || customer?.unvan},
            </h3>
            <p className={styles.infoText}>
              Talebiniz üzerine, sigorta ihtiyacınız için çalışmakta olduğumuz
              tüm sigorta şirketlerinden alternatifli olarak teklif çalışmaları
              yapılmış, en uygun teklifimiz başta olmak üzere, tüm tekliflerimiz
              aşağıda bilgilerinize sunulmuştur.
            </p>
          </div>

          <div className={styles.formContainer}>
            <div className={styles.formItem}>
              <h5 className={styles.title}>Müşteri Bilgileri</h5>
              <Row gutter={[24, 24]}>
                <Col span={12}>
                  <SWInput
                    id="name"
                    editable={false}
                    height={inputHeight}
                    label="Ad Soyad / Ünvan"
                    onChange={(value) => null}
                    value={customer?.isim_soyisim || customer?.unvan || ''}
                  />
                </Col>
                <Col span={12}>
                  <SWInput
                    editable={false}
                    id="identityNumber"
                    height={inputHeight}
                    label="TC Kimlik / Vergi No"
                    onChange={(value) => null}
                    value={customer?.tc || customer?.vergi_no || ''}
                  />
                </Col>
                <Col span={12}>
                  <SWInput
                    id="email"
                    editable={false}
                    height={inputHeight}
                    label="E-posta Adresi"
                    value={customer?.eposta || '-'}
                    onChange={(value) => null}
                  />
                </Col>
                <Col span={12}>
                  <SWInput
                    id="gsm"
                    label="GSM No"
                    editable={false}
                    height={inputHeight}
                    format={'0 (###) ### ## ##'}
                    value={customer?.telefon || '-'}
                    onChange={(value) => null}
                  />
                </Col>
              </Row>
            </div>

            {offerDetail.template_headers?.length > 0 ? (
              <div className={styles.formItem}>
                <h5 className={styles.title}>Diğer Bilgiler</h5>
                <Row gutter={[24, 24]}>
                  {offerDetail.template_headers?.map((item, index) => (
                    <Col span={12} key={index}>
                      <SWInput
                        id={item.label}
                        editable={false}
                        label={item.label}
                        value={item.value}
                        height={inputHeight}
                        onChange={(value) => null}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            ) : null}
          </div>

          {bestCompany?.name && (
            <div className={`${styles.formContainer} ${styles.filledForm}`}>
              <div className={styles.formItem}>
                <h5
                  className={
                    styles.title
                  }>{`En Uygun ${offerType} Teklifimiz`}</h5>
                <div className={styles.bestOfferContainer}>
                  <div className={styles.companyInfo}>
                    <img
                      alt={bestCompany.name}
                      src={bestCompany.logo}
                      className={styles.companyLogo}
                    />
                    <div className={styles.packageInfo}>
                      <div className={styles.companyName}>
                        {bestCompany.name}
                      </div>
                      {bestCompany?.packageName && (
                        <div className={styles.packageName}>
                          {bestCompany.packageName}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.infoContainer}>
                    <span className={styles.infoLabel}>Peşin Fiyat:</span>
                    <SWInput
                      type={'money'}
                      editable={false}
                      id={'cashPrice'}
                      displayType={'text'}
                      onChange={(value) => null}
                      value={bestCompany?.cashPrice || '-'}
                      style={{
                        fontSize: 16,
                        fontWeight: 'bold'
                      }}
                    />
                  </div>
                  <div className={styles.infoContainer}>
                    <span className={styles.infoLabel}>Taksitli Fiyat:</span>
                    <SWInput
                      type={'money'}
                      editable={false}
                      displayType={'text'}
                      id={'installmentPrice'}
                      onChange={(value) => null}
                      value={bestCompany?.installmentPrice || '-'}
                      style={{
                        fontSize: 16,
                        fontWeight: 'bold'
                      }}
                    />
                  </div>
                </div>

                {offerDetail.specials?.length > 0 ||
                bestCompany?.installmentDue ? (
                  <>
                    <div className={styles.additionalInfoContainer}>
                      {offerDetail.specials.map((item, index) => (
                        <div className={styles.additionalInfo} key={index}>
                          <CheckOutlined className={styles.checkIcon} />
                          <div className={styles.info}>
                            <div className={styles.label}>{item.label}</div>
                            <SWInput
                              id={item.label}
                              editable={false}
                              value={item.value}
                              displayType={'text'}
                              style={{
                                fontSize: 14
                              }}
                              onChange={(value) => null}
                              type={
                                String(item.value).includes('₺')
                                  ? 'money'
                                  : 'text'
                              }
                            />
                          </div>
                        </div>
                      ))}
                    </div>

                    {bestCompany?.installmentDue && (
                      <div className={styles.additionalInfo}>
                        <InfoCircleOutlined className={styles.checkIcon} />
                        <div className={styles.info}>
                          <div
                            className={
                              styles.installmentDue
                            }>{`${bestCompany.installmentDue} Taksit İmkanı`}</div>
                        </div>
                      </div>
                    )}
                  </>
                ) : null}
              </div>
            </div>
          )}

          {renderCompanyList({ list: otherCompanyList, hidePrint: true })}
          {renderCompanyList({
            list: otherCompanyList?.slice(0, firstPageCompanyLength),
            onlyPrint: true
          })}
          {renderCompanyList({
            list: otherCompanyList?.slice(
              firstPageCompanyLength,
              secondPageCompanyLength
            ),
            onlyPrint: true,
            beforePageBreak: true
          })}
          {renderCompanyList({
            list: otherCompanyList?.slice(
              secondPageCompanyLength,
              otherCompanyList?.length - 1
            ),
            onlyPrint: true,
            beforePageBreak: true
          })}

          <div className={styles.generalDescriptionContainer}>
            <h4 className={styles.title}>GENEL AÇIKLAMALAR</h4>
            <p>
              SİBA SİGORTA tarafından hazırlanan işbu fiyat teklifi formu,
              müşteri tarafından talep edilen sigorta ürününe istinaden, hizmet
              koşulları ve fiyat tekliflerini alternatifli olarak sunabilmek
              amacıyla iletilmiştir. İlgili sigorta şirketlerinin teklif
              formundaki fiyatlar ile genel ve özel poliçe şartları nihai olarak
              geçerlidir.
            </p>

            <div className={styles.authorizedInfoContainer}>
              <div className={styles.title}>Saygılarımızla,</div>
              <h4 className={styles.authorizedFullName}>SİBA SİGORTA</h4>
            </div>
          </div>
        </div>

        <footer className={styles.footer}>
          <div></div>
          <div className={styles.description}>
            <div>
              Teklifinizi onaylayarak poliçeleştirmek ya da detaylı bilgi almak
              için bize aşağıdaki iletişim bilgilerimizden ulaşabilirsiniz.
            </div>
            <div>
              Teklif Sorumlusu: <span>{offerDetail?.fullName}</span>
            </div>
            <div>
              E-posta: <span>{offerDetail.email}</span>
            </div>
          </div>
          <div className={styles.footerLogoContainer}>
            <img
              width={70}
              height={70}
              src="/assets/signature_logo.png"
              alt="Siba Sigorta İmza Logo"
            />
            <a href="https://www.sibasigorta.com.tr">www.sibasigorta.com.tr</a>
          </div>
        </footer>
      </div>
    </Spin>
  );
});

export default OfferPdfUiPage;
