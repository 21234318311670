import { useEffect, useMemo, useRef, useState } from 'react';

import { observer } from 'mobx-react';
import { sortBy } from 'lodash';
import { Modal } from 'antd';
import cn from 'classnames';

import styles from '../../../Pages/auth/offers/create/index.module.scss';
import { CustomerType } from '../../../Types/Enums/CustomerEnums';
import { customerStore } from '../../../GlobalStore';
import { ICustomerListModalProp } from './types';
import { SWInput, SWRadio } from '../../UI';

const SWCustomerListModal = observer(
  ({ onClose, visible, onSelect }: ICustomerListModalProp) => {
    const filterTimeout = useRef<NodeJS.Timeout>();

    const [customerType, setCustomerType] = useState<CustomerType>(
      CustomerType.Individual
    );

    useEffect(() => {
      if (customerType && visible) {
        getCustomerList();
      }
    }, [customerType, visible]);

    const getCustomerList = (value?: string) => {
      if (customerType === CustomerType.Individual) {
        clearTimeout(filterTimeout?.current);
        filterTimeout.current = setTimeout(
          () => {
            customerStore.fillCustomers({
              Filter: [
                {
                  filterDb: 'birey',
                  filterType: 'isim_soyisim',
                  filterValue: value || ''
                },
                {
                  filterDb: 'birey',
                  filterType: 'tc',
                  filterValue: value || ''
                }
              ]
            });
          },
          value ? 600 : 0
        );
      } else {
        clearTimeout(filterTimeout?.current);
        filterTimeout.current = setTimeout(
          () => {
            customerStore.fillCustomers({
              Filter: [
                {
                  filterDb: 'kurum',
                  filterType: 'unvan',
                  filterValue: value || ''
                },
                {
                  filterDb: 'kurum',
                  filterType: 'vergi_no',
                  filterValue: value || ''
                }
              ]
            });
          },
          value ? 600 : 0
        );
      }
    };

    const customerList = useMemo(() => {
      return sortBy(customerStore?.customers, (c) =>
        c.typeEnum === CustomerType.Individual ? c.isim_soyisim : c.unvan
      );
    }, [customerStore?.customers]);

    return (
      <Modal
        width={'30%'}
        destroyOnClose
        okButtonProps={{
          style: { display: 'none' }
        }}
        open={visible}
        onCancel={onClose}
        cancelText={'Kapat'}
        title={`Müşteriler`}>
        <>
          <br />
          <div className={cn(styles.row, styles.center)}>
            <SWRadio
              type="card"
              label="Bireysel"
              checked={customerType === CustomerType.Individual}
              onCheck={() => setCustomerType(CustomerType.Individual)}
            />
            <SWRadio
              type="card"
              label="Kurumsal"
              checked={customerType === CustomerType.Corporate}
              onCheck={() => setCustomerType(CustomerType.Corporate)}
            />
          </div>
          <div className={styles.customerList}>
            <SWInput
              height={35}
              id={'search'}
              value={undefined}
              placeholder="Ara"
              onChange={getCustomerList}
              style={{ border: { borderWidth: 1 } }}
            />
            {customerList?.length ? (
              customerList?.map((customer, index) => (
                <div
                  onClick={() => {
                    onSelect(customer);
                    onClose();
                  }}
                  className={styles.customer}
                  key={index}>
                  <div className={styles.info}>
                    {customer?.isim_soyisim || customer?.unvan}
                  </div>
                </div>
              ))
            ) : (
              <div className={styles.customer}>
                <div className={styles.info}>Veri bulunamadı...</div>
              </div>
            )}
          </div>
        </>
      </Modal>
    );
  }
);

export default SWCustomerListModal;
