import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { MdOutlineLogout } from 'react-icons/md';
import { Image, Layout, Menu } from 'antd';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { isStandardUserAuthorizeDeniedFromRoute } from '../../../Helpers/roleHelpers';
import { useSideMenu } from '../../../Hooks/useSideMenu';
import { PageLink } from '../../../Constants/PageLink';
import { SWButton } from '../index';
import './styles.scss';

const { Sider } = Layout;

const SWSider = observer(() => {
  const {
    menuItems,
    selectedKeys,
    onOpenChange,
    openMenuKeys,
    setOpenMenuKeys
  } = useSideMenu();

  const [collapsed, setCollapsed] = useState(false);

  const deleteAuthenticationToken = () => {
    window.localStorage.removeItem('@AuthToken');
    const origin = window.location.origin;
    window.location.href = `${origin}${PageLink.Login}`;
  };

  return (
    <Sider
      width={'25rem'}
      breakpoint="lg"
      className="sider"
      collapsedWidth={0}
      collapsed={collapsed}
      theme={'dark'}
      onCollapse={(value) => setCollapsed(value)}>
      <div className="sider__content_container">
        <div>
          <div className="sider__logo">
            <Link to={PageLink.CreateOffer}>
              <Image
                preview={false}
                src={`/assets/logo.png`}
                alt={'Siba Sigorta Logo'}
                className="sider__logo-img"
              />
            </Link>
          </div>
          <Menu
            theme="dark"
            mode="inline"
            // items={menuItems}
            // selectedKeys={selectedKeys}
            onOpenChange={onOpenChange}
            // openKeys={collapsed ? [] : openMenuKeys}
          >
            {menuItems?.map((item) => {
              if (
                (item.path &&
                  isStandardUserAuthorizeDeniedFromRoute(item.path)) ||
                !item.visible
              ) {
                return null;
              }
              if (!item.path || item.children) {
                return (
                  <div
                    className={cn('sider__menu-item', {
                      ['active']: selectedKeys.includes(item.key)
                    })}
                    key={item.key}>
                    <Menu.Item
                      onClick={() =>
                        setOpenMenuKeys((prev) =>
                          prev?.includes(item.key) ? undefined : [item.key]
                        )
                      }
                      icon={item.icon}
                      key={item.key}>
                      <div className="sider__menu-item--child">
                        <span>{item.label}</span>
                        <span>
                          {openMenuKeys?.includes(item.key) ? (
                            <HiChevronUp size={18} />
                          ) : (
                            <HiChevronDown size={18} />
                          )}
                        </span>
                      </div>
                    </Menu.Item>

                    {!collapsed &&
                    openMenuKeys?.includes(item.key) &&
                    item.children ? (
                      <div className="sider__menu-item--child-container">
                        {item.children?.map((c) =>
                          !c.path ||
                          !c.visible ||
                          isStandardUserAuthorizeDeniedFromRoute(
                            c.path
                          ) ? null : (
                            <Link
                              key={item.key}
                              to={c.path}
                              className={cn(
                                'sider__menu-item',
                                'sider__menu-item--child',
                                {
                                  ['active']: selectedKeys.includes(c.key)
                                }
                              )}>
                              <Menu.Item icon={c.icon} key={c.key}>
                                {c.label}
                              </Menu.Item>
                            </Link>
                          )
                        )}
                      </div>
                    ) : null}
                  </div>
                );
              }
              return (
                <Link
                  key={item.key}
                  to={item.path}
                  className={cn('sider__menu-item', {
                    ['active']: selectedKeys.includes(item.key)
                  })}>
                  <Menu.Item icon={item.icon} key={item.key}>
                    {item.label}
                  </Menu.Item>
                </Link>
              );
            })}
          </Menu>
        </div>
        {!collapsed && (
          <div className="sider__footer">
            <a
              href="https://ratiotechnologies.com.tr/"
              className="sider__footer-logo-link"
              target="_blank">
              <Image
                preview={false}
                alt="'Ratio Tech Logo"
                src="/assets/ratio-logo.png"
                className="sider__footer-logo"
              />
            </a>
            <SWButton
              type="secondary"
              content={'Logout'}
              className="sider__footer--button"
              onClick={deleteAuthenticationToken}
              icon={<MdOutlineLogout style={{ width: 16, height: 16 }} />}
            />
          </div>
        )}
      </div>
    </Sider>
  );
});

export default SWSider;
