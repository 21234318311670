export const parseJson = (str: string | undefined) => {
  try {
    if (str) return JSON.parse(str);
    return JSON.parse('[]');
  } catch (e) {
    return JSON.parse('[]');
  }
};

export const stringifyJson = (data: any) => {
  try {
    return JSON.stringify(data);
  } catch (e) {
    return JSON.stringify([]);
  }
};
