import { useEffect, useMemo, useRef, useState } from 'react';

import { CheckOutlined, InfoCircleOutlined } from '@ant-design/icons';
import * as htmlToImage from 'html-to-image';
import { observer } from 'mobx-react';
import { Spin } from 'antd';
import cn from 'classnames';

import { routerStore } from '../../../GlobalStore';
import { SWInput } from '../../../Components/UI';
import styles from './index.module.scss';
import { OfferUIProps } from '../types';
import { sortBy } from 'lodash';

const OfferJpegUiPage = observer(() => {
  const domEl = useRef<HTMLDivElement>(null);

  const [offerDetailLoading, setOfferDetailLoading] = useState<boolean>(true);
  const [offerDetail, setOfferDetail] = useState<
    OfferUIProps & { fullName: string; email: string }
  >();
  useEffect(() => {
    if (!offerDetailLoading) downloadImage();
  }, [offerDetailLoading]);

  const downloadImage = async () => {
    if (!domEl?.current) return;

    const dataUrl = await htmlToImage.toJpeg(domEl.current, {
      quality: 1,
      width: 746,
      canvasWidth: 746,
      cacheBust: true
    });
    const link = document.createElement('a');
    link.download = `${offerDetail?.refNo || 'Siba_Sigorta'}.jpeg`;
    link.href = dataUrl;
    link.click();
    window.close();
  };

  useEffect(() => {
    routerStore.getItem<OfferUIProps>('OfferDetail', (data) => {
      if (data) {
        const splitCreator = data.creator?.split('_');
        if (splitCreator?.length && splitCreator.length >= 3) {
          const [name, surname, email] = splitCreator;
          setOfferDetail({ ...data, fullName: `${name} ${surname}`, email });
          setTimeout(() => {
            setOfferDetailLoading(false);
          }, 4000);
        }
      } else {
        window.close();
      }
    });
  }, []);

  const offerType = useMemo(() => {
    return offerDetail?.type_name;
  }, [offerDetail?.type_name]);

  const customer = useMemo(() => {
    return offerDetail?.customer;
  }, [offerDetail?.customer]);

  const bestOffer = useMemo(() => {
    return offerDetail?.ins_companies?.find(
      (c) => c.uid === offerDetail?.bestOfferId
    );
  }, [offerDetail?.bestOfferId, offerDetail?.ins_companies]);

  return (
    <Spin spinning={offerDetailLoading}>
      <div ref={domEl}>
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <div className={styles.companyContainer}>
              <img
                width={250}
                height={60}
                src="/assets/logo.png"
                alt="Siba Sigorta Logo"
                crossOrigin="anonymous"
              />
              <div className={styles.companyLinkContainer}>
                <a href="https://www.sibasigorta.com.tr">
                  www.sibasigorta.com.tr
                </a>
                <div>GÜVENCE.</div>
              </div>
            </div>
            <div className={styles.datesContainer}>
              <div className={styles.dateContainer}>
                <div className={styles.tableLabel}>Teklif Tarihi</div>
                <div className={styles.tableValue}>
                  {offerDetail?.offerDate}
                </div>
              </div>
              <div className={styles.dateContainer}>
                <div className={styles.tableLabel}>Geçerlilik Tarihi</div>
                <div className={styles.tableValue}>
                  {offerDetail?.offerValidityDate}
                </div>
              </div>
            </div>
            <div>
              <div
                className={cn(styles.customerContainer, {
                  [styles.borderBottom]: !offerDetail?.template_headers?.length
                })}>
                <div className={styles.tableLabel}>Müşteri Bilgileri</div>
                <div
                  className={cn(styles.customerInfoContainer, {
                    [styles.longText]:
                      (
                        customer?.isim_soyisim ||
                        customer?.unvan ||
                        customer?.eposta ||
                        ''
                      )?.length > 20
                  })}>
                  <div className={styles.customerInfoItem}>
                    <div className={styles.tableLabel}>Ad Soyad / Ünvan</div>
                    <div className={styles.tableValue}>
                      {customer?.isim_soyisim || customer?.unvan || ''}
                    </div>
                  </div>
                  <div className={styles.customerInfoItem}>
                    <div className={styles.tableLabel}>
                      TC Kimlik / Vergi No
                    </div>
                    <div className={styles.tableValue}>
                      {customer?.tc || customer?.vergi_no || ''}
                    </div>
                  </div>
                  <div className={styles.customerInfoItem}>
                    <div className={styles.tableLabel}>E-posta Adresi</div>
                    <div className={styles.tableValue}>
                      {customer?.eposta || ''}
                    </div>
                  </div>
                  <div className={styles.customerInfoItem}>
                    <div className={styles.tableLabel}>GSM No</div>
                    <div className={styles.tableValue}>
                      <SWInput
                        id={'phone'}
                        editable={false}
                        displayType={'text'}
                        onChange={() => null}
                        style={{ fontSize: 12 }}
                        value={customer?.telefon || '-'}
                        format={
                          customer?.telefon ? '0 (###) ### ## ##' : undefined
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              {offerDetail?.template_headers?.length ? (
                <div className={styles.customerContainer}>
                  <div className={styles.tableLabel}>Diğer Bilgiler</div>
                  <div
                    className={cn(
                      styles.customerInfoContainer,
                      styles.oneLine
                    )}>
                    {offerDetail?.template_headers?.map((item, index) => (
                      <div className={styles.customerInfoItem} key={index}>
                        <div className={styles.tableLabel}>{item.label}</div>
                        <div className={styles.tableValue}>{item.value}</div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
            {bestOffer && (
              <div className={styles.bestOfferContainer}>
                <div
                  className={
                    styles.header
                  }>{`EN UYGUN ${offerType?.toLocaleUpperCase(
                  'tr'
                )} TEKLİFİMİZ`}</div>
                <div className={styles.offerItem}>
                  <div className={styles.tableLabel}>
                    <span>Sigorta Şirketi</span>
                  </div>
                  <div className={styles.tableValue}>
                    <div className={styles.bestCompanyInfo}>
                      <img
                        src={bestOffer.logo}
                        alt={bestOffer.name}
                        crossOrigin="anonymous"
                      />
                      <span className={styles.companyName}>
                        {bestOffer?.name}
                      </span>
                      {bestOffer?.packageName && (
                        <span className={styles.packageName}>
                          {bestOffer?.packageName}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.offerItems}>
                  <div className={styles.offerItem}>
                    <div className={styles.tableLabel}>Peşin Fiyat</div>
                    <div className={styles.tableValue}>
                      <SWInput
                        type="money"
                        id={'cashPrice'}
                        editable={false}
                        displayType="text"
                        onChange={() => null}
                        value={bestOffer?.cashPrice || '-'}
                        style={{
                          fontSize: 14,
                          fontWeight: 'bold',
                          textAlign: 'center'
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.offerItem}>
                    <div className={styles.tableLabel}>Taksitli Fiyat</div>
                    <div className={styles.tableValue}>
                      <SWInput
                        type="money"
                        editable={false}
                        displayType="text"
                        onChange={() => null}
                        id={'installmentPrice'}
                        value={bestOffer?.installmentPrice || '-'}
                        style={{
                          fontSize: 14,
                          fontWeight: 'bold',
                          textAlign: 'center'
                        }}
                      />
                    </div>
                  </div>
                </div>
                {offerDetail?.specials?.length || bestOffer?.installmentDue ? (
                  <div className={styles.offerContent}>
                    <div className={styles.tableLabel}>Ürün İçeriği</div>
                    <div className={styles.contentList}>
                      {offerDetail?.specials?.map((item, index) => (
                        <div className={styles.contentItem} key={index}>
                          <CheckOutlined className={styles.checkIcon} />
                          <div className={styles.contentItemInfo}>
                            <div className={styles.contentItemLabel}>
                              {item.label}:
                            </div>
                            <SWInput
                              id={item.label}
                              editable={false}
                              value={item.value}
                              displayType={'text'}
                              style={{
                                fontSize: 14,
                                fontWeight: '300'
                              }}
                              onChange={(value) => null}
                              type={
                                String(item.value).includes('₺')
                                  ? 'money'
                                  : 'text'
                              }
                            />
                          </div>
                        </div>
                      ))}
                      {bestOffer?.installmentDue && (
                        <div className={styles.contentItem}>
                          <InfoCircleOutlined className={styles.checkIcon} />
                          <div>{`${bestOffer.installmentDue} Taksit İmkanı`}</div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            )}
            <div className={styles.otherOffers}>
              <div className={styles.header}>
                {bestOffer?.id
                  ? `Diğer ${offerType} Tekliflerimiz`
                  : `${offerType} Tekliflerimiz`}
              </div>
              <div className={styles.tableHeader}>
                <div
                  className={cn(styles.tableLabel, styles.tableCompanyHeader)}>
                  Sigorta Şirketi
                </div>
                <div className={styles.tableLabel}>Peşin Fiyat</div>
                <div className={styles.tableLabel}>Taksitli Fiyat</div>
                <div className={styles.tableLabel}>Taksit Sayısı</div>
              </div>
              <div className={styles.tableContent}>
                {sortBy(offerDetail?.ins_companies, 'cashPrice')?.map(
                  (company, index) =>
                    company?.uid === bestOffer?.uid ? null : (
                      <div className={styles.tableContentItem} key={index}>
                        <div className={styles.companyInfo}>
                          <img
                            src={company.logo}
                            alt={company.name}
                            crossOrigin="anonymous"
                            className={styles.companyLogo}
                          />
                          <div className={styles.companyName}>
                            {company.name}
                          </div>
                          {company?.packageName && (
                            <div className={styles.packageName}>
                              {company.packageName}
                            </div>
                          )}
                        </div>
                        <div className={styles.inputContainer}>
                          <SWInput
                            type={'money'}
                            editable={false}
                            id={'cashPrice'}
                            displayType="text"
                            style={{ textAlign: 'center' }}
                            value={company.cashPrice || '-'}
                            onChange={(value) => null}
                          />
                        </div>
                        <div className={styles.inputContainer}>
                          <SWInput
                            type={'money'}
                            editable={false}
                            displayType="text"
                            id={'installmentPrice'}
                            style={{ textAlign: 'center' }}
                            onChange={(value) => null}
                            value={company.installmentPrice || '-'}
                          />
                        </div>
                        <div className={styles.inputContainer}>
                          <SWInput
                            type={'text'}
                            editable={false}
                            displayType="text"
                            id={'installmentDue'}
                            style={{ textAlign: 'center' }}
                            onChange={(value) => null}
                            value={company.installmentDue || '-'}
                          />
                        </div>
                      </div>
                    )
                )}
              </div>
            </div>
            <div className={styles.descriptionText}>
              SİBA SİGORTA tarafından hazırlanan işbu fiyat teklifi formu,
              müşteri tarafından talep edilen sigorta ürününe istinaden, hizmet
              koşulları ve fiyat tekliflerini alternatifli olarak sunabilmek
              amacıyla iletilmiştir. İlgili sigorta şirketlerinin teklif
              formundaki fiyatlar ile genel ve özel poliçe şartları nihai olarak
              geçerlidir.
            </div>

            <footer className={styles.footer}>
              <div></div>
              <div className={styles.description}>
                <div>
                  Teklifinizi onaylayarak poliçeleştirmek ya da detaylı bilgi
                  almak için bize aşağıdaki iletişim bilgilerimizden
                  ulaşabilirsiniz.
                </div>
                <div>
                  Teklif Sorumlusu: <span>{offerDetail?.fullName}</span>
                </div>
                <div>
                  E-posta: <span>{offerDetail?.email}</span>
                </div>
              </div>
              <div className={styles.footerLogoContainer}>
                <img
                  width={70}
                  height={70}
                  crossOrigin="anonymous"
                  src="/assets/signature_logo.png"
                  alt="Siba Sigorta İmza Logo"
                />
                <a href="https://www.sibasigorta.com.tr">
                  www.sibasigorta.com.tr
                </a>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </Spin>
  );
});

export default OfferJpegUiPage;
