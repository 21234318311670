import { makeAutoObservable } from 'mobx';

import { AuthUserInfo } from '../../Types/Interfaces/UserInterfaces';
import { RoleEnum } from '../../Types/Enums/RoleEnum';

export default class AuthStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    const token = window.localStorage.getItem('@AuthToken');
    if (token) {
      this.isAuthenticated = true;
      this.authToken = token;
      const stringValue = window?.atob(token.split('.')[1]);
      if (stringValue) {
        const authTokenJSON = JSON.parse(stringValue);
        const roles: Array<RoleEnum> = [];
        if (authTokenJSON?.is_admin || authTokenJSON?.is_staff) {
          if (authTokenJSON?.is_staff) {
            roles.push(RoleEnum.Staff);
          }
          if (authTokenJSON?.is_admin) {
            roles.push(RoleEnum.Admin);
          }
        } else {
          roles.push(RoleEnum.Standard);
        }

        this.userInfo = authTokenJSON;
        this.roles = roles;
      }
    }
  }

  userInfo: AuthUserInfo | undefined = undefined;
  roles: Array<RoleEnum> = [];
  isAuthenticated: boolean = false;
  authToken: string | undefined;
}
