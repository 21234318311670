import { useCallback, useEffect, useMemo, useState } from 'react';

import { NumberFormatBase, NumericFormat } from 'react-number-format';
import { Col, DatePicker, Input, Row, Table } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment-timezone';
import dayjs from 'dayjs';

import { addInputToOfferTemplateFormRules } from '../../../FormValidation/Rules/updateOfferTemplateFormRules';
import { IOfferTypeSpecialField } from '../../../Types/Interfaces/OfferTypeInterfaces';
import { availableInputTypes } from '../../../Constants/AvailableInputTypes';
import { formattedDateToDayJs } from '../../../Helpers/dateHelpers';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { ITemplateSpecialFieldsProp } from './types';
import { SWButton, SWSelectBox } from '../../UI';
import styles from './index.module.scss';

const SWTemplateSpecialFields = ({
  offerType,
  onUpdated,
  isCopyOffer,
  inputDisable,
  onInputUpdated,
  isOfferHistory
}: ITemplateSpecialFieldsProp) => {
  const {
    control,
    formState: { isValid },
    reset,
    trigger,
    setValue,
    getValues
  } = useForm({
    resolver: yupResolver(addInputToOfferTemplateFormRules)
  });

  const [inputs, setInputs] = useState<Array<IOfferTypeSpecialField>>([]);

  const onSubmit = () => {
    onUpdated?.([...(inputs || []), getValues()]);
    setInputs((prev) => [...prev, getValues()]);
    reset({ label: undefined, type: 'text' });
  };

  const template = useMemo(() => {
    return offerType;
  }, [offerType]);

  useEffect(() => {
    if (template?.specials) {
      setInputs(template.specials);
      onUpdated?.(template.specials);
    }
  }, [template]);

  const renderByType = useCallback(
    (
      type: IOfferTypeSpecialField['type'],
      field: { label: string; value?: string }
    ) => {
      const { label, value } = field;
      if (type === 'numeric' || (isCopyOffer && Number(value))) {
        return (
          <NumberFormatBase
            placeholder={label}
            customInput={Input}
            disabled={inputDisable}
            defaultValue={isCopyOffer ? value : undefined}
            onValueChange={(values) =>
              onInputUpdated?.({ label, value: values.value })
            }
          />
        );
      } else if (type === 'money' || (isCopyOffer && value?.includes('₺'))) {
        return (
          <NumericFormat
            prefix={`₺ `}
            decimalScale={2}
            placeholder={label}
            customInput={Input}
            decimalSeparator={','}
            thousandSeparator={'.'}
            disabled={inputDisable}
            defaultValue={isCopyOffer ? value : undefined}
            onValueChange={(values) =>
              onInputUpdated?.({ label, value: values.formattedValue })
            }
          />
        );
      } else if (
        type === 'date' ||
        (isCopyOffer && moment(formattedDateToDayJs(value)).isValid())
      ) {
        return (
          <DatePicker
            placeholder={label}
            disabled={inputDisable}
            defaultValue={
              isCopyOffer && moment(formattedDateToDayJs(value)).isValid()
                ? dayjs(formattedDateToDayJs(value))
                : undefined
            }
            onChange={(e) =>
              onInputUpdated?.({ label, value: e?.format('DD-MM-YYYY') })
            }
          />
        );
      }
      return (
        <Input
          placeholder={label}
          disabled={inputDisable}
          value={isOfferHistory ? value : undefined}
          defaultValue={isCopyOffer ? value : undefined}
          onChange={(e) =>
            onInputUpdated?.({ label, value: e?.currentTarget?.value })
          }
        />
      );
    },
    []
  );

  const specialConditionsTableCols: ColumnsType<{
    label: string;
    type: string;
  }> = [
    {
      title: 'Şart',
      key: 'label',
      dataIndex: 'label',
      width: '50%'
    },
    {
      title: 'Değer',
      key: 'type',
      dataIndex: 'type',
      width: '50%',
      render: (value, field) => renderByType(value, field)
    },
    {
      title: '',
      key: 'remove',
      dataIndex: 'remove',
      render: (_, field) =>
        !isOfferHistory && (
          <MinusCircleOutlined
            onClick={() => {
              const newInputs = inputs?.filter((i) => i.label !== field.label);
              setInputs(newInputs);
              onUpdated?.(newInputs || []);
              onInputUpdated?.({ label: field.label, value: undefined });
            }}
            className={styles.minusIcon}
          />
        )
    }
  ];
  return (
    <Table
      dataSource={inputs}
      columns={specialConditionsTableCols}
      rowKey={(row) => `${row.label}${row.type}`}
      footer={() =>
        !isOfferHistory && (
          <Row gutter={16} align="bottom">
            <Col span={11}>
              <label className={styles.label}>Şart</label>
              <Controller
                name={'label'}
                control={control}
                render={({ field }) => (
                  <Input {...field} placeholder="Belirlenmek istenen şart" />
                )}
              />
            </Col>
            <Col span={11}>
              <label className={styles.label}>Değer</label>
              <SWSelectBox
                value={undefined}
                options={availableInputTypes}
                placeholder="Belirlenmek istenen şartın girdi tipi"
                onChange={(value) => {
                  setValue('type', (value ? value.join('') : '') as any);
                  trigger('type');
                }}
              />
            </Col>
            <Col span={2}>
              <SWButton
                content={'Ekle'}
                disabled={!isValid}
                onClick={onSubmit}
              />
            </Col>
          </Row>
        )
      }
    />
  );
};

export default SWTemplateSpecialFields;
