import { DefaultToastOptions } from 'react-hot-toast';

export const ToastConfig: DefaultToastOptions = {
  duration: 1250,
  style: {
    fontSize: 14,
    padding: 10,
    color: '#fff',
    borderRadius: 4
  },
  success: {
    style: {
      background: '#28a745'
    }
  },
  error: {
    style: {
      background: '#dc3545'
    }
  }
};
