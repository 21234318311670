export const HttpServiceBaseUrl = 'https://siba-backend.com';

const ServiceUrls = {
  Login: '/api/token',

  Company: {
    Create: '/insurance-company-create',
    List: '/insurance-companies-get',
    Get: '/insurance-company-get',
    Delete: '/insurance-company-delete',
    Update: '/insurance-company-update'
  },
  User: {
    Create: '/api/users',
    List: '/api/users/get',
    Update: '/api/users/update',
    Delete: '/api/users/delete',
    CurrentUserInfo: '/api/users/me'
  },
  OfferType: {
    Create: '/offer-type-create',
    List: '/offer-types-get',
    Get: '/offer-type-get',
    Delete: '/offer-type-delete',
    Update: '/offer-type-update'
  },
  Customer: {
    General: {
      List: '/customers/'
    },
    Individual: {
      List: '/customers-birey-get',
      Get: '/customer-birey-get',
      Delete: '/customer-birey-delete',
      Update: '/customer-birey-update',
      Create: '/customer-birey-create'
    },
    Corporate: {
      List: '/customers-kurum-get',
      Get: '/customer-kurum-get',
      Delete: '/customer-kurum-delete',
      Update: '/customer-update',
      Create: '/customer-kurum-create'
    }
  },
  Offer: {
    Create: '/offer-create/',
    List: '/offer-get',
    Delete: '/offer-delete'
  },

  Branch: {
    List: '/bagli-sube/'
  },
  Excel: {
    Download: '/download-excel/'
  }
};

export default ServiceUrls;
