import { RcFile, UploadChangeParam } from 'antd/es/upload';

export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

export const handleFileChange = (
  info: UploadChangeParam,
  callback: (url: string) => void,
  removeCallback?: () => void
) => {
  if (info.file.status === 'removed') {
    removeCallback?.();
    return;
  }
  getBase64(info.file.originFileObj as RcFile, (url) => callback(url));
};
