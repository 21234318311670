import React, { useEffect, useMemo, useState } from 'react';

import datePickerLocale from 'antd/es/date-picker/locale/tr_TR';
import { Col, DatePicker, Divider, Row, Spin } from 'antd';
import { PatternFormat } from 'react-number-format';
import { PlusOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';

import {
  SWCustomerListModal,
  SWTemplateCompanyTable,
  SWTemplateHeaderFields,
  SWTemplateSpecialFields
} from '../../../../Components/Composite';
import {
  authStore,
  companyStore,
  customerStore,
  offerStore,
  routerStore
} from '../../../../GlobalStore';
import { IOfferTypeCompany } from '../../../../Types/Interfaces/OfferTypeInterfaces';
import { CreateOfferResponseDto } from '../../../../HttpService/DTOs/responseDtos';
import { CreateOfferRequestDto } from '../../../../HttpService/DTOs/requestDtos';
import { ICustomer } from '../../../../Types/Interfaces/CustomerInterfaces';
import { SWButton, SWRadio, SWSelectBox } from '../../../../Components/UI';
import { CustomerType } from '../../../../Types/Enums/CustomerEnums';
import { mapCompanyLogo } from '../../../../Helpers/mapCompanyLogo';
import { useHttpService } from '../../../../Hooks/useHttpService';
import { PageLink } from '../../../../Constants/PageLink';
import { OfferUIProps } from '../../../offer-ui/types';
import SWCustomerModal from '../../customers/modal';
import styles from './index.module.scss';

const { RangePicker } = DatePicker;

const CreateOfferPage = observer(() => {
  const [createOffer, , , createOfferLoading] = useHttpService<
    CreateOfferRequestDto,
    CreateOfferResponseDto
  >('CreateOffer');

  const [isCustomerSelectorModalVisible, setIsCustomerSelectorModalVisible] =
    useState<boolean>(false);
  const [isCustomerCreateModalVisible, setIsCustomerCreateModalVisible] =
    useState<boolean>(false);

  const [offerTemplateId, setOfferTemplateId] = useState<number>();

  const [headerInputs, setHeaderInputs] = useState<
    Array<{ label: string; value: any }>
  >([]);
  const [specialInputs, setSpecialInputs] = useState<
    Array<{ label: string; value: any }>
  >([]);
  const [dates, setDates] = useState({
    offerDate: '',
    validityDate: ''
  });

  const [companies, setCompanies] = useState<Array<IOfferTypeCompany>>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer>();
  const [fileType, setFileType] = useState<'jpg' | 'pdf'>();
  const [bestOfferId, setBestOfferId] = useState<string>();
  const [isSubmitted, setIsSubmitted] = useState<boolean>();

  useEffect(() => {
    return () => customerStore.fillCustomers();
  }, []);

  useEffect(() => {
    if (offerTemplateId) offerStore.fillTemplateByOfferId(offerTemplateId);
  }, [offerTemplateId]);

  const offerTemplates = useMemo(() => {
    return offerStore.offerTemplates;
  }, [offerStore.offerTemplates]);

  const template = useMemo(() => {
    return offerStore.template;
  }, [offerStore.template]);

  const selectedOfferTemplate = useMemo(() => {
    return offerTemplates?.find((ot) => ot.id === offerTemplateId);
  }, [offerTemplates, offerTemplateId]);

  const createNewOffer = () => {
    if (!selectedCustomer) return;

    let isConfirm = true;
    if (!bestOfferId) {
      isConfirm = window.confirm(
        'En iyi teklifi işaretlemediniz. Teklifinizi bu şekilde oluşturmak istediğinizden emin misiniz?'
      );
    }

    if (isConfirm) {
      const data = {
        customerId: selectedCustomer.id,
        offer_inside: {
          ins_companies: mapCompanyLogo(companies),
          template_headers: headerInputs.map((item) => ({
            label: item.label,
            value: item.value?.toLocaleUpperCase('tr')
          })),
          specials: specialInputs.map((item) => ({
            label: item.label,
            value: item.value?.toLocaleUpperCase('tr')
          })),
          bestOfferId,
          pay_type: '',
          offerDate: dates.offerDate,
          offerValidityDate: dates.validityDate,
          type_name: template?.type_name,
          templateId: template?.id || -1,
          user_info: selectedCustomer
        }
      };
      createOffer(
        {
          ...data,
          offer_inside: JSON.stringify(data.offer_inside)
        },
        {
          onSuccess: (response) => {
            const { userInfo } = authStore;
            setIsSubmitted(true);
            routerStore.setItem<OfferUIProps>('OfferDetail', {
              ...data.offer_inside,
              customer: selectedCustomer,
              creator: `${userInfo?.name}_${userInfo?.surname}_${userInfo?.email}`,
              type_name: template?.type_name,
              refNo: typeof response === 'string' ? response : ''
            });
          }
        }
      );
    }
  };

  const download = () => {
    let url = `${window.location.origin}${PageLink.OfferPdfDownload}`;
    if (fileType === 'jpg')
      url = `${window.location.origin}${PageLink.OfferJpegDownload}`;
    window.open(url, '_blank');
  };

  const isFormValid = useMemo(() => {
    let condition = !!selectedCustomer;

    return (
      condition && companies?.length && dates?.offerDate && dates?.validityDate
    );
  }, [selectedCustomer, companies, dates, fileType]);

  const closeModal = () => {
    setIsCustomerSelectorModalVisible(false);
  };

  return (
    <>
      <SWCustomerListModal
        onClose={closeModal}
        onSelect={setSelectedCustomer}
        visible={isCustomerSelectorModalVisible}
      />

      <SWCustomerModal
        visible={isCustomerCreateModalVisible}
        onClose={(customer) => {
          setIsCustomerCreateModalVisible(false);
          setSelectedCustomer(customer);
        }}
      />

      <Row gutter={[16, 24]} id={'createForm'}>
        <Col span={24}>
          <Row gutter={[16, 16]} align={'middle'}>
            <Col span={8}>
              <img
                src="/assets/logo.png"
                alt="Siba Sigorta Logo"
                className={styles.logo}
              />
            </Col>
            <Col span={8}>
              <Divider>
                <h3 className={styles.textCenter}>Teklif Formu</h3>
              </Divider>
            </Col>
            <Col span={8}>
              <Row justify="end" gutter={[24, 24]}>
                <Col className={styles.customerSelectorBtn}>
                  <SWButton
                    icon={<PlusOutlined />}
                    content={'Yeni Müşteri'}
                    onClick={() => setIsCustomerCreateModalVisible(true)}
                  />
                </Col>
                <Col className={styles.customerSelectorBtn}>
                  <SWButton
                    content={'Müşteri Seç'}
                    onClick={() => {
                      customerStore.customers = [];
                      setIsCustomerSelectorModalVisible(true);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        {selectedCustomer && (
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <div className={styles.customerInfo}>
                  <div className={styles.customerInfo__label}>
                    {selectedCustomer?.typeEnum === CustomerType.Individual
                      ? 'Ad Soyad'
                      : 'Ünvan'}
                  </div>
                  <div className={styles.customerInfo__value}>
                    {selectedCustomer?.isim_soyisim || selectedCustomer?.unvan}
                  </div>
                </div>
              </Col>
              <Col span={6}>
                <div className={styles.customerInfo}>
                  <div className={styles.customerInfo__label}>
                    {selectedCustomer?.typeEnum === CustomerType.Individual
                      ? 'T.C. Kimlik No'
                      : 'Vergi No'}
                  </div>
                  <div className={styles.customerInfo__value}>
                    {selectedCustomer?.tc || selectedCustomer?.vergi_no}
                  </div>
                </div>
              </Col>
              <Col span={6}>
                <div className={styles.customerInfo}>
                  <div className={styles.customerInfo__label}>
                    {selectedCustomer?.typeEnum === CustomerType.Individual
                      ? 'E-posta Adresi'
                      : 'Vergi Dairesi'}
                  </div>
                  <div className={styles.customerInfo__value}>
                    {selectedCustomer?.eposta ||
                      selectedCustomer?.vergi_dairesi}
                  </div>
                </div>
              </Col>
              {selectedCustomer?.typeEnum === CustomerType.Individual && (
                <Col span={6}>
                  <div className={styles.customerInfo}>
                    <div className={styles.customerInfo__label}>GSM No</div>
                    <PatternFormat
                      displayType="text"
                      format={'0 (###) ### ## ##'}
                      value={selectedCustomer.telefon}
                      className={styles.customerInfo__value}
                    />
                  </div>
                </Col>
              )}
            </Row>
          </Col>
        )}
        {selectedCustomer ? (
          <>
            <Col span={24}>
              <div className={styles.dateContainer}>
                <div className={styles.labelContainer}>
                  <div className={styles.label}>
                    Teklif Tarihi <span className={styles.required}>*</span>
                  </div>
                  <div className={styles.label}>
                    Geçerlilik Tarihi <span className={styles.required}>*</span>
                  </div>
                </div>
                <RangePicker
                  locale={{
                    ...datePickerLocale,
                    lang: {
                      ...datePickerLocale.lang,
                      rangePlaceholder: ['Teklif Tarihi', 'Geçerlilik Tarihi']
                    }
                  }}
                  //@ts-ignore
                  defaultValue={[dayjs()]}
                  presets={[
                    {
                      label: '1 Hafta Sonrası',
                      value: [dayjs(), dayjs().add(1, 'week')]
                    },
                    {
                      label: '1 Ay Sonrası',
                      value: [dayjs(), dayjs().add(1, 'month')]
                    }
                  ]}
                  onChange={(dates) => {
                    if (dates?.length === 2 && dates[0] && dates[1]) {
                      const offerDate = dates[0];
                      const validityDate = dates[1];
                      setDates({
                        offerDate: offerDate.format('DD.MM.YYYY'),
                        validityDate: validityDate.format('DD.MM.YYYY')
                      });
                    }
                  }}
                />
              </div>
            </Col>
            <Col span={24}>
              <Spin spinning={offerStore.offerTemplatesLoading}>
                <label className={styles.label}>
                  Sigorta Türü <span className={styles.required}>*</span>
                </label>
                <SWSelectBox
                  options={(offerTemplates || [])?.map((ot) => ({
                    label: ot.type_name,
                    value: ot.id
                  }))}
                  placeholder="Sigorta Tipi Seç"
                  value={offerTemplateId ? [offerTemplateId] : undefined}
                  onChange={(value) =>
                    setOfferTemplateId(Number(value?.join('')))
                  }
                />
              </Spin>
            </Col>
            {selectedOfferTemplate && (
              <Col span={24}>
                <Spin spinning={offerStore.templateLoading}>
                  <Row gutter={[24, 24]}>
                    <Col span={24}>
                      <Row>
                        <Col span={24}>
                          <h4 className={styles.textCenter}>
                            {selectedOfferTemplate?.type_name} Sigortası Teklif
                            Formu
                          </h4>
                        </Col>
                        <Col span={24}>
                          <div className={styles.sectionContainer}>
                            <Row gutter={[16, 8]}>
                              <Col span={24}>
                                <h4>Diğer Bilgiler</h4>
                              </Col>
                              <Col span={24}>
                                <SWTemplateHeaderFields
                                  offerType={template}
                                  onInputUpdated={(input) => {
                                    setHeaderInputs((prev) => {
                                      if (typeof input.value === 'undefined')
                                        return prev.filter(
                                          (p) => p.label !== input.label
                                        );
                                      return [
                                        ...prev.filter(
                                          (p) => p.label !== input?.label
                                        ),
                                        input
                                      ];
                                    });
                                  }}
                                  onUpdate={() => null}
                                />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <div className={styles.sectionContainer}>
                        <Row gutter={[16, 8]}>
                          <Col span={24}>
                            <h4>
                              Şirket Teklifleri{' '}
                              <span className={styles.required}>*</span>
                            </h4>
                          </Col>
                          <Col span={24}>
                            <SWTemplateCompanyTable
                              offerType={template}
                              onUpdatedV2={(data) => {
                                setCompanies(data.companies);
                                setBestOfferId(data.bestOfferId);
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Col span={24}>
                      <div className={styles.sectionContainer}>
                        <Row gutter={[16, 8]}>
                          <Col span={24}>
                            <h4>En İyi Teklif İçeriği</h4>
                          </Col>
                          <Col span={24}>
                            <SWTemplateSpecialFields
                              offerType={template}
                              onUpdated={(inputs) => null}
                              onInputUpdated={(input) => {
                                setSpecialInputs((prev) => {
                                  if (typeof input.value === 'undefined')
                                    return prev.filter(
                                      (p) => p.label !== input.label
                                    );
                                  return [
                                    ...prev.filter(
                                      (p) => p.label !== input?.label
                                    ),
                                    input
                                  ];
                                });
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    {!isSubmitted && (
                      <Col span={24}>
                        <SWButton
                          content={'Oluştur'}
                          disabled={!isFormValid}
                          onClick={createNewOffer}
                          loading={createOfferLoading}
                        />
                      </Col>
                    )}
                    {isSubmitted && (
                      <Col span={24}>
                        <label className={styles.label}>
                          Dosya Türü <span className={styles.required}>*</span>
                        </label>
                        <div className={styles.fileTypes}>
                          <SWRadio
                            label={'PDF'}
                            checked={fileType === 'pdf'}
                            onCheck={() => setFileType('pdf')}
                          />
                          <SWRadio
                            label={'JPEG'}
                            checked={fileType === 'jpg'}
                            onCheck={() => setFileType('jpg')}
                          />
                          <SWButton
                            content={'İndir'}
                            onClick={download}
                            disabled={!isSubmitted}
                          />
                        </div>
                      </Col>
                    )}
                  </Row>
                </Spin>
              </Col>
            )}
          </>
        ) : (
          <Col span={24}>
            <h4 className={styles.textCenter}>
              Teklif formu oluşturmak için lütfen müşteri seçiniz.
            </h4>
          </Col>
        )}
      </Row>
    </>
  );
});

export default CreateOfferPage;
