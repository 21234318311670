import { Radio } from 'antd';
import cn from 'classnames';

import styles from './index.module.scss';
import { IRadioProp } from './types';

const SWRadio = ({ type, label, checked, onCheck, disabled }: IRadioProp) => {
  return (
    <Radio
      checked={checked}
      onChange={onCheck}
      disabled={disabled}
      rootClassName={cn({
        [styles.container]: type === 'card',
        [styles.noLabel]: typeof label === 'undefined',
        [styles.disabled]: disabled
      })}>
      {label}
    </Radio>
  );
};

export default SWRadio;
