export const enum PageLink {
  InsuranceCompanies = '/insurance-companies',
  OfferTemplates = '/offers/templates',
  UserManagement = '/user-management',
  RegisteredOffers = '/offers/registered-offers',
  Customers = '/customers',
  Login = '/login',
  OfferPdfDownload = '/offer-download/pdf',
  OfferJpegDownload = '/offer-download/jpeg',
  CreateOffer = '/create-offer'
}

export const authRoutes = [
  PageLink.InsuranceCompanies,
  PageLink.OfferTemplates,
  PageLink.UserManagement,
  PageLink.Customers,
  PageLink.RegisteredOffers,
  PageLink.CreateOffer,
  PageLink.OfferJpegDownload,
  PageLink.OfferPdfDownload
];
export const guestRoutes = [PageLink.Login];

export const authorizedRoutesForStandardUser = [
  PageLink.Customers,
  PageLink.RegisteredOffers,
  PageLink.CreateOffer,
  PageLink.OfferJpegDownload,
  PageLink.OfferPdfDownload
];
