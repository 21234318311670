import yup from '../../Utils/yup';

export const createOrUpdateCorporateCustomerFormRules = yup.object().shape({
  unvan: yup.string().min(3).required(),
  vergi_dairesi: yup.string().required(),
  vergi_no: yup.string().taxNumber().required(),
  // bagli_sube: yup.string().required(),
  bagli_sube: yup.string().optional(),
  customerType: yup.string()
});
