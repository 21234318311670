import { useState } from 'react';
import Cropper from "react-easy-crop";
import { Point, Area } from "react-easy-crop/types";
import { Modal } from 'antd';
import { ICropImageModalProp } from './types';
import { getBase64 } from 'src/Helpers/uploadHelper';
import { RcFile } from 'antd/es/upload';

const SWCropImageModal = ({ image, visible, onClose, onCancel }: ICropImageModalProp) => {
    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedImage, setCroppedImage] = useState<string>("");

    const onCropComplete = async (croppedArea: Area, croppedAreaPixels: Area) => {
        if (!Number.isNaN(croppedAreaPixels.width) && !Number.isNaN(croppedAreaPixels.height)) {
            const croppedImage = await getCroppedImg(image, croppedAreaPixels);
            typeof croppedImage === 'string' && setCroppedImage(croppedImage);
        }
    };

    const createImage = (url: string): Promise<HTMLImageElement> => 
        new Promise(async (resolve, reject) => {
            const image = new Image();
            image.addEventListener('load', () => resolve(image));
            image.addEventListener('error', (error) => reject(error));
            image.setAttribute('crossOrigin', 'anonymous');
            if (url.startsWith("data:image/jpeg;base64") && /^[A-Za-z0-9+/=]+$/.test(url.substring(23))) {
                image.src = url;
            } else {
                const response = await fetch(url, { method: "GET", mode: "cors", cache: "no-cache", credentials: "same-origin" });
                getBase64(await response.blob() as RcFile, (src) => image.src = src);
            }
        });

    const getRadianAngle = (degreeValue : number) => {
        return (degreeValue * Math.PI) / 180
    }

    const rotateSize = (width: number, height: number, rotation: number) => {
        const rotRad = getRadianAngle(rotation)
      
        return {
          width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
          height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
        }
      }

    const getCroppedImg = async (imageSrc: string, pixelCrop: Area, rotation: number = 0, flip = { horizontal: false, vertical: false }): Promise<string | null> => {
        const image = await createImage(imageSrc)
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        if (!ctx) {
            return null
        }

        const rotRad = getRadianAngle(rotation)

        // calculate bounding box of the rotated image
        const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
            image.width,
            image.height,
            rotation
        )

        // set canvas size to match the bounding box
        canvas.width = bBoxWidth
        canvas.height = bBoxHeight

        // translate canvas context to a central location to allow rotating and flipping around the center
        ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
        ctx.rotate(rotRad)
        ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
        ctx.translate(-image.width / 2, -image.height / 2)

        // draw rotated image
        ctx.drawImage(image, 0, 0)

        const croppedCanvas = document.createElement('canvas')

        const croppedCtx = croppedCanvas.getContext('2d')

        if (!croppedCtx) {
            return null
        }

        // Set the size of the cropped canvas
        croppedCanvas.width = pixelCrop.width
        croppedCanvas.height = pixelCrop.height

        // Draw the cropped image onto the new canvas
        croppedCtx.drawImage(
            canvas,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height
        )

        // As Base64 string
        return croppedCanvas.toDataURL('image/jpeg');

        // As a blob
        /* return new Promise((resolve, reject) => {
            croppedCanvas.toBlob((file) => {
                resolve(URL.createObjectURL(file))
            }, 'image/jpeg')
        }) */
    }

    return (
        <Modal
            destroyOnClose
            okText='Kaydet'
            onOk={() => onClose(croppedImage)}
            open={visible}
            width={600}
            onCancel={onCancel}
        >
            <div style={{ padding: '150px' }}>
                <Cropper
                    image={image}
                    crop={crop}
                    zoom={zoom}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    style={{ containerStyle: { height: '300px' } }}
                />
            </div>
        </Modal>
    );
}

export default SWCropImageModal;