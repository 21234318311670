import React, { useEffect, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { sortBy } from 'lodash';
import { Col } from 'antd';

import { createOrUpdateCorporateCustomerFormRules } from '../../../FormValidation/Rules/createOrUpdateCorporateCustomerFormRules';
import { CorporateCustomerFormProp, CorporateCustomerInputType } from './types';
import { MockDataBranches } from '../../../Constants/TaxConstants';
import { CustomerType } from '../../../Types/Enums/CustomerEnums';
import { SWFormErrorMessage } from '../../UI/SWFormErrorMessage';
import taxOfficesJson from '../../../Constants/taxOffices.json';
import citiesJson from '../../../Constants/cities.json';
import { SWInput, SWSelectBox } from '../../UI';

const inputHeight = 32;
const SWCorporateCustomerForm = ({
  inputs,
  handleInput,
  handleValid
}: CorporateCustomerFormProp) => {
  const {
    control,
    getValues,
    setValue,
    trigger,
    formState: { errors, isValid }
  } = useForm({
    mode: 'all',
    resolver: yupResolver(createOrUpdateCorporateCustomerFormRules)
  });

  useEffect(() => {
    handleValid(isValid);
  }, [isValid]);

  useEffect(() => {
    if (inputs) {
      setValue('customerType', CustomerType.Corporate);
      setValue('unvan', inputs?.unvan || '');
      setValue('vergi_no', inputs?.vergi_no || '');
      setValue('vergi_dairesi', inputs?.vergi_dairesi || '');
      setValue('bagli_sube', inputs?.bagli_sube || '');
    }
  }, [inputs]);

  const updateInput = (field: keyof CorporateCustomerInputType, e: string) => {
    // @ts-ignore
    setValue(field, e);
    trigger(field as any);
    handleInput(getValues() as any);
  };

  const taxOffices = useMemo(() => {
    let dataByCity: any = [];
    citiesJson.forEach((c) => {
      dataByCity.push({
        label: c.name,
        value: c.name,
        children: sortBy(
          taxOfficesJson
            .filter((to) => to.il_id === c.id)
            .map((t) => ({ label: t.vergi_dairesi, value: t.vergi_dairesi })),
          'label'
        )
      });
    });
    return sortBy(dataByCity, 'label');
  }, []);

  const branches = useMemo(() => MockDataBranches, []);

  return (
    <>
      <Col span={12}>
        <div>
          Ünvan{' '}
          <span
            style={{
              color: 'var(--color-primary)'
            }}>
            *
          </span>
        </div>
        <Controller
          control={control}
          name={'unvan'}
          render={({ field }) => (
            <>
              <SWInput
                {...field}
                id={'unvan'}
                height={inputHeight}
                style={{ border: { borderWidth: 1 } }}
                onChange={(value) => updateInput('unvan', value)}
              />
              <SWFormErrorMessage message={errors?.unvan?.message} />
            </>
          )}
        />
      </Col>
      <Col span={12}>
        <div>
          Vergi Dairesi{' '}
          <span
            style={{
              color: 'var(--color-primary)'
            }}>
            *
          </span>
        </div>
        <Controller
          control={control}
          name={'vergi_dairesi'}
          render={({ field }) => (
            <>
              <SWSelectBox
                {...field}
                options={taxOffices}
                value={[field.value]}
                onChange={(value) => {
                  updateInput(
                    'vergi_dairesi',
                    (value?.length > 1 ? value[1] : value.join('')) as string
                  );
                }}
              />
              <SWFormErrorMessage message={errors?.vergi_dairesi?.message} />
            </>
          )}
        />
      </Col>
      <Col span={12}>
        <div>
          Vergi No{' '}
          <span
            style={{
              color: 'var(--color-primary)'
            }}>
            *
          </span>
        </div>
        <Controller
          control={control}
          name={'vergi_no'}
          render={({ field }) => (
            <>
              <SWInput
                {...field}
                id={'vergi_no'}
                format="##########"
                height={inputHeight}
                style={{ border: { borderWidth: 1 } }}
                onChange={(value) => updateInput('vergi_no', value)}
              />
              <SWFormErrorMessage message={errors?.vergi_no?.message} />
            </>
          )}
        />
      </Col>
      {/*<Col span={12}>*/}
      {/*  <div>*/}
      {/*    Bağlı Şube{' '}*/}
      {/*    <span*/}
      {/*      style={{*/}
      {/*        color: 'var(--color-primary)'*/}
      {/*      }}>*/}
      {/*      **/}
      {/*    </span>*/}
      {/*  </div>*/}
      {/*  <Controller*/}
      {/*    control={control}*/}
      {/*    name={'bagli_sube'}*/}
      {/*    render={({ field }) => (*/}
      {/*      <>*/}
      {/*        <SWSelectBox*/}
      {/*          {...field}*/}
      {/*          options={branches}*/}
      {/*          value={[field.value]}*/}
      {/*          onChange={(value) => updateInput('bagli_sube', value?.join(''))}*/}
      {/*        />*/}
      {/*        <SWFormErrorMessage message={errors?.bagli_sube?.message} />*/}
      {/*      </>*/}
      {/*    )}*/}
      {/*  />*/}
      {/*</Col>*/}
    </>
  );
};

export default SWCorporateCustomerForm;
