import { configure } from 'mobx';

import CompanyStore from './CompanyStore';
import OfferStore from './OfferStore';
import UserStore from './UserStore';
import AuthStore from './AuthStore';
import AppStore from './AppStore';
import CustomerStore from './CustomerStore';
import RouterStore from './RouterStore';

configure({
  enforceActions: 'never'
});

export const routerStore = new RouterStore();
export const authStore = new AuthStore();
export const appStore = new AppStore();
export const companyStore = new CompanyStore(authStore.isAuthenticated);
export const userStore = new UserStore(authStore.isAuthenticated);
export const offerStore = new OfferStore(authStore.isAuthenticated);
export const customerStore = new CustomerStore(authStore.isAuthenticated);
