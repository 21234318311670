import { makeAutoObservable } from 'mobx';

import { toast } from 'react-hot-toast';

import {
  CreateCompanyRequestDto,
  UpdateCompanyRequestDto
} from '../../HttpService/DTOs/requestDtos';
import { ICompany } from '../../Types/Interfaces/ICompany';
import HttpService from '../../HttpService';
import { ActionCallback } from '../types';
import { sortBy } from 'lodash';

export default class CompanyStore {
  constructor(private isAuthenticated: boolean) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.fillCompanies();
  }

  companiesLoading: boolean = false;
  companies: Array<ICompany> | undefined;

  fillCompanies() {
    if (this.isAuthenticated) {
      this.companiesLoading = true;
      HttpService.GetCompanies()
        .then((res) => {
          this.companies = sortBy(res?.data, 'name');
        })
        .catch((e) => console.log('Company Store getCompanies', e))
        .finally(() => (this.companiesLoading = false));
    }
  }

  createCompany(
    payload: CreateCompanyRequestDto | undefined,
    callback?: ActionCallback<ICompany>
  ) {
    if (typeof payload === 'undefined') return;

    HttpService.CreateCompany(payload)
      .then((res) => {
        const resData = res?.data;
        if (!resData?.detail) {
          callback?.onSuccess?.(resData);
          this.companies?.push(resData);
          toast.success('Şirket oluşturuldu');
        }
      })
      .catch((e) => console.log('Create company', e))
      .finally(() => callback?.onFinally?.());
  }

  updateCompany(
    company: UpdateCompanyRequestDto | undefined,
    callback?: ActionCallback<any>
  ) {
    if (typeof company === 'undefined' || !this.companies) return;

    HttpService.UpdateCompany(company)
      .then((res) => {
        const findCompanyIndex = this.companies?.findIndex(
          (c) => c.id === company?.id
        );
        if (
          typeof findCompanyIndex !== 'undefined' &&
          findCompanyIndex > -1 &&
          this.companies
        ) {
          this.companies[findCompanyIndex].name = company.name;
          this.companies[findCompanyIndex].logo = company.logo;
          this.companies[findCompanyIndex].date_last_updated =
            new Date().toString();
        }
        toast.success('Şirket güncellendi');
        callback?.onSuccess?.();
      })
      .catch((e) => console.log('Update company', e))
      .finally(() => callback?.onFinally?.());
  }

  deleteCompany(companyId: string, callback?: ActionCallback<any>) {
    HttpService.DeleteCompany({ companyId })
      .then((res) => {
        if (!res?.data?.detail) {
          this.companies = this.companies?.filter((c) => c.id !== companyId);
          toast.success('Şirket silindi');
        }
      })
      .catch((e) => console.log('Delete company', e));
  }
}
