import React, { useEffect, useMemo, useRef, useState } from 'react';

import { CloudDownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Divider, Row, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { observer } from 'mobx-react';
import { sortBy } from 'lodash';

import {
  MockDataBranches,
  MockDataTaxOffices
} from '../../../Constants/TaxConstants';
import {
  CorporateCustomerFilterableFields,
  ICustomer,
  IndividualCustomerFilterableFields
} from '../../../Types/Interfaces/CustomerInterfaces';
import { DownloadCustomerListResponseDto } from '../../../HttpService/DTOs/responseDtos';
import { DownloadCustomerListRequestDto } from '../../../HttpService/DTOs/requestDtos';
import { createCustomersFilter } from '../../../Constants/CustomerConstants';
import { SWButton, SWInput, SWSelectBox } from '../../../Components/UI';
import { CustomerType } from '../../../Types/Enums/CustomerEnums';
import { authStore, customerStore } from '../../../GlobalStore';
import { useHttpService } from '../../../Hooks/useHttpService';
import { RoleEnum } from '../../../Types/Enums/RoleEnum';
import styles from './index.module.scss';
import SWCustomerModal from './modal';

const defaultFilter: CorporateCustomerFilterableFields &
  IndividualCustomerFilterableFields = {
  bagli_sube: undefined,
  vergi_dairesi: undefined,
  vergi_no: undefined,
  typeEnum: undefined,

  eposta: undefined,
  telefon: undefined,
  tc: undefined
};

const CustomerManagementPage = observer(() => {
  const filterTimeout = useRef<NodeJS.Timeout>();
  const [downloadAsExcel, , , downloadLoading] = useHttpService<
    DownloadCustomerListRequestDto,
    DownloadCustomerListResponseDto
  >('DownloadCustomerListAsExcel');

  const [searchValue, setSearchValue] = useState<string>();
  const [filters, setFilters] = useState(defaultFilter);
  const [selected, setSelected] = useState<ICustomer>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  useEffect(() => {
    if (filters || searchValue) {
      clearTimeout(filterTimeout?.current);
      filterTimeout.current = setTimeout(() => {
        customerStore.fillCustomers(
          createCustomersFilter({ search: searchValue, filter: filters })
        );
      }, 1000);
    }
  }, [filters, searchValue]);

  useEffect(() => {
    if (customerStore.customerCreated || customerStore.customerUpdated) {
      setFilters(defaultFilter);
      setSearchValue('');
    }
  }, [customerStore.customerCreated, customerStore.customerUpdated]);

  const downloadCustomerListAsExcel = () => {
    downloadAsExcel(
      {
        customerType: filters.typeEnum as CustomerType
      },
      {
        onSuccess: (data) => {
          const url = URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          const outputFileName = `${filters.typeEnum?.toLocaleLowerCase(
            'tr'
          )}_musteriler.xlsx`;
          link.href = url;
          link.setAttribute('download', outputFileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      }
    );
  };

  const customers = useMemo(() => {
    return sortBy(customerStore.customers, (i) =>
      i.typeEnum === CustomerType.Individual ? i.isim_soyisim : i.unvan
    );
  }, [customerStore.customers]);

  const isStaff = useMemo(() => {
    return authStore.roles.includes(RoleEnum.Staff);
  }, [authStore.roles]);

  const taxOffices = useMemo(() => MockDataTaxOffices, []);
  const branches = useMemo(() => MockDataBranches, []);

  const customerTableCols: ColumnsType<ICustomer> = [
    {
      title: 'Müşteri Tipi',
      key: 'musteri_tip',
      dataIndex: 'musteri_tip',
      fixed: 'left',
      render: (_, customer) => <div>{customer?.typeString}</div>
    },
    {
      title: 'Ad Soyad / Unvan',
      key: 'isim_soyisim',
      dataIndex: 'isim_soyisim',
      fixed: 'left',
      render: (_, customer) => customer?.isim_soyisim || customer?.unvan
    },
    {
      title: 'TC Kimlik No',
      key: 'tc',
      dataIndex: 'tc',
      render: (_, customer) => customer?.tc || '-'
    },
    {
      title: 'Vergi Dairesi',
      key: 'vergi_daire',
      dataIndex: 'vergi_daire',
      render: (_, customer) => customer?.vergi_dairesi || '-'
    },
    {
      title: 'Vergi No',
      key: 'vergi_no',
      dataIndex: 'vergi_no',
      render: (_, customer) => customer?.vergi_no || '-'
    },
    // {
    //   title: 'Bağlı Şube',
    //   key: 'bagli_sube',
    //   dataIndex: 'bagli_sube',
    //   render: (_, customer) => customer?.bagli_sube || '-'
    // },
    {
      title: 'GSM No',
      key: 'telefon',
      dataIndex: 'telefon',
      render: (_, customer) => {
        if (!customer?.telefon) return '-';
        return (
          <a className={styles.link} href={`tel:0${customer.telefon}`}>
            0{customer.telefon}
          </a>
        );
      }
    },
    {
      title: 'E-posta Adresi',
      key: 'eposta',
      dataIndex: 'eposta',
      render: (_, customer) => customer?.eposta || '-'
    },
    {
      title: 'İşlem',
      key: 'actions',
      dataIndex: 'actions',
      align: 'right',
      render: (_: any, customer: ICustomer) => (
        <Row gutter={[10, 10]} justify="end">
          <Col>
            <SWButton
              outlined
              type={'secondary'}
              content={'Düzenle'}
              onClick={() => {
                setSelected(customer);
                setIsModalVisible(true);
              }}
            />
          </Col>
          {isStaff && (
            <Col>
              <SWButton
                outlined
                content={'Sil'}
                onClick={() => customerStore.deleteCustomer(customer)}
              />
            </Col>
          )}
        </Row>
      )
    }
  ];

  return (
    <>
      <Divider>
        <h2>Müşteri Yönetimi</h2>
      </Divider>
      <div className={styles.btnContainer}>
        <SWButton
          icon={<PlusOutlined />}
          content={'Yeni Müşteri'}
          onClick={() => setIsModalVisible(true)}
        />
      </div>
      <div className={styles.tableContainer}>
        <div className={styles.card}>
          <div className={styles.searchContainer}>
            <SWInput
              height={35}
              id={'search'}
              value={searchValue}
              placeholder={
                filters?.typeEnum
                  ? filters?.typeEnum === CustomerType.Individual
                    ? 'İsme Göre Ara'
                    : 'Ünvana Göre Ara'
                  : 'Ara'
              }
              style={{ border: { borderWidth: 1 } }}
              onChange={(value) => {
                setSearchValue(value);
                // searchTable(value)
              }}
            />
          </div>
          <div className={styles.filterContainer}>
            <div className={styles.filterItem}>
              <div className={styles.label}>Müşteri Tipi</div>
              <SWSelectBox
                value={
                  typeof filters?.typeEnum !== 'undefined'
                    ? [filters.typeEnum]
                    : undefined
                }
                options={[
                  { label: 'Bireysel', value: CustomerType.Individual },
                  { label: 'Kurumsal', value: CustomerType.Corporate }
                ]}
                onChange={(value) =>
                  setFilters({
                    ...defaultFilter,
                    bagli_sube: filters.bagli_sube,
                    typeEnum: value?.join('') as any
                  })
                }
              />
            </div>
            {filters.typeEnum === CustomerType.Corporate && (
              <>
                <div className={styles.filterItem}>
                  <div className={styles.label}>Vergi Dairesi</div>
                  <SWSelectBox
                    value={
                      typeof filters?.vergi_dairesi !== 'undefined'
                        ? [filters.vergi_dairesi]
                        : undefined
                    }
                    options={taxOffices}
                    onChange={(value) =>
                      setFilters({
                        ...filters,
                        vergi_dairesi: value?.join('') as any
                      })
                    }
                  />
                </div>
                <div className={styles.filterItem}>
                  <div className={styles.label}>Vergi No</div>
                  <SWInput
                    height={32}
                    id={'taxNumber'}
                    value={filters?.vergi_no}
                    style={{
                      border: { borderWidth: 1, borderColor: '#d9d9d9' }
                    }}
                    onChange={(value) =>
                      setFilters({
                        ...filters,
                        vergi_no: value
                      })
                    }
                  />
                </div>
              </>
            )}
            {filters?.typeEnum === CustomerType.Individual && (
              <>
                <div className={styles.filterItem}>
                  <div className={styles.label}>E-posta Adresi</div>
                  <SWInput
                    height={32}
                    id={'email'}
                    value={filters?.eposta}
                    style={{
                      border: { borderWidth: 1, borderColor: '#d9d9d9' }
                    }}
                    onChange={(value) =>
                      setFilters({
                        ...filters,
                        eposta: value
                      })
                    }
                  />
                </div>
                <div className={styles.filterItem}>
                  <div className={styles.label}>TC Kimlik No</div>
                  <SWInput
                    id={'tc'}
                    height={32}
                    value={filters?.tc}
                    format="###########"
                    style={{
                      border: { borderWidth: 1, borderColor: '#d9d9d9' }
                    }}
                    onChange={(value) => setFilters({ ...filters, tc: value })}
                  />
                </div>
              </>
            )}
            {typeof filters.typeEnum !== 'undefined' && (
              <div className={styles.filterItem}>
                <SWButton
                  content={'İndir'}
                  loading={downloadLoading}
                  icon={<CloudDownloadOutlined />}
                  onClick={downloadCustomerListAsExcel}
                />
              </div>
            )}
            {/*<div className={styles.filterItem}>*/}
            {/*  <div className={styles.label}>Bağlı Şube</div>*/}
            {/*  <SWSelectBox*/}
            {/*    value={*/}
            {/*      typeof filters?.bagli_sube !== 'undefined'*/}
            {/*        ? [filters.bagli_sube]*/}
            {/*        : undefined*/}
            {/*    }*/}
            {/*    options={branches}*/}
            {/*    onChange={(value) =>*/}
            {/*      setFilters({*/}
            {/*        ...filters,*/}
            {/*        bagli_sube: value?.join('') as any*/}
            {/*      })*/}
            {/*    }*/}
            {/*  />*/}
            {/*</div>*/}
          </div>
        </div>
        <Table
          dataSource={customers}
          rowKey={(user) => user.id}
          columns={customerTableCols}
          loading={customerStore.customersLoading}
        />
      </div>

      <SWCustomerModal
        selected={selected}
        visible={isModalVisible}
        onClose={() => {
          setSelected(undefined);
          setIsModalVisible(false);
        }}
      />
    </>
  );
});

export default CustomerManagementPage;
