import { AxiosError } from 'axios';
import { ResponseError } from '../HttpService/types';

export const serviceErrorHelper = (
  error: AxiosError<any, any>
): ResponseError => {
  return (
    error?.response?.data?.message ||
    error?.response?.data?.errors ||
    error?.message ||
    ''
  );
};
