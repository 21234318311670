interface IProp {
  noMargin?: boolean;
  message: string | undefined;
}
export const SWFormErrorMessage = ({ message, noMargin }: IProp) =>
  message ? (
    <div
      style={{
        fontSize: 12,
        color: 'var(--color-primary)',
        marginTop: noMargin ? undefined : 3
      }}>
      {message}
    </div>
  ) : null;
