import React, { useEffect, useState } from 'react';
import { UploadChangeParam } from 'antd/es/upload';
import { Input, Modal, Upload } from 'antd';
import { handleFileChange } from '../../../Helpers/uploadHelper';
import { ICompany } from '../../../Types/Interfaces/ICompany';
import { companyStore } from '../../../GlobalStore';
import { EditCompanyModalProp } from './types';
import styles from './index.module.scss';
import { SWCropImageModal } from '../';

const SWEditCompanyModal = ({
  onOk,
  title,
  visible,
  company,
  onCancel
}: EditCompanyModalProp) => {
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [uploadedImgUrl, setUploadedImgUrl] = useState<string>();
  const [inputs, setInputs] = useState<ICompany>();
  const [visibleCropModal, setVisibleCropModal] = useState<boolean>(false);

  const handleChange = (info: UploadChangeParam) => {
    if (typeof inputs === 'undefined') return;
    handleFileChange(
      info,
      (url) => {
        setUploadedImgUrl(url);
        setInputs({ ...inputs, logo: url });
        setVisibleCropModal(true);
      },
      () => {
        setUploadedImgUrl(undefined);
        setInputs({ ...inputs, logo: company?.logo || '' });
      }
    );
  };

  const updateCompany = () => {
    if (inputs) {
      setUpdateLoading(true);
      companyStore.updateCompany(
        {
          id: inputs?.id,
          logo: inputs?.logo,
          name: inputs?.name
        },
        {
          onSuccess: () => {
            resetModal();
            onOk();
          },
          onFinally: () => setUpdateLoading(false)
        }
      );
    }
  };

  const resetModal = () => {
    setUploadedImgUrl(undefined);
    setInputs({
      id: '-1',
      name: '',
      logo: ''
    });
    onCancel?.();
  };

  const handleCloseVisibleCropModal = (croppedImage: string | null) => {
    if(!!croppedImage) {
      setInputs({ ...inputs, logo: croppedImage } as ICompany);
      setUploadedImgUrl(croppedImage);
    }
    setVisibleCropModal(false);
  }

  const handleCancelVisibleCropModal = () => {
    setVisibleCropModal(false);
  }

  useEffect(() => {
    if (visible && typeof company !== 'undefined') {
      setInputs(company);
    }
  }, [visible, company]);

  return (
    <Modal
      title={title}
      destroyOnClose
      okText={'Kaydet'}
      onOk={updateCompany}
      okButtonProps={{
        loading: updateLoading,
        disabled: !inputs?.name || !inputs.logo
      }}
      cancelButtonProps={{ disabled: updateLoading }}
      onCancel={() => (updateLoading ? null : resetModal())}
      open={visible}>
      <div className={styles.formItem}>
        <label htmlFor="companyName">Firma Adı</label>
        <Input
          value={inputs?.name}
          onChange={(e) =>
            setInputs({
              ...inputs,
              name: e?.currentTarget?.value
            } as ICompany)
          }
          id="companyName"
        />
      </div>
      <div className={styles.formItem}>
        <label>Firma Logo</label>
        <Upload
          maxCount={1}
          name="avatar"
          accept={'image/*,.svg'}
          className={styles.uploadBtn}
          onPreview={() => {
            setUploadedImgUrl(company?.logo);
            setVisibleCropModal(true);
          }}
          fileList={[
            {
              uid: '1',
              name: 'currentLogo',
              url: uploadedImgUrl || company?.logo
            }
          ]}
          listType="picture-card"
          onChange={handleChange}>
          <span>Upload</span>
        </Upload>
      </div>
      <SWCropImageModal
        image={uploadedImgUrl || company?.logo || ""}
        visible={visibleCropModal}
        onClose={handleCloseVisibleCropModal}
        onCancel={handleCancelVisibleCropModal}
      />
    </Modal>
  );
};

export default SWEditCompanyModal;
