import { Route, Routes, useNavigate } from 'react-router';
import React, { useEffect } from 'react';

import { LoginPage } from '../Pages';
import { PageLink } from '../Constants/PageLink';

const GuestRouter = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(PageLink.Login);
  }, []);

  return (
    <Routes>
      <Route path={PageLink.Login} Component={LoginPage} />
    </Routes>
  );
};

export default GuestRouter;
