import React, { useEffect, useMemo, useState } from 'react';
import { SorterResult } from 'antd/es/table/interface';
import { Col, Divider, Row, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { EyeOutlined, CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { isArray } from 'lodash';
import { IOfferHistoryItem } from '../../../../Types/Interfaces/OfferInterfaces';
import { SWEditOfferTemplateDrawer } from '../../../../Components/Composite';
import { authStore, offerStore } from '../../../../GlobalStore';
import { RoleEnum } from '../../../../Types/Enums/RoleEnum';
import { SWButton, SWInput, SWDatePicker } from '../../../../Components/UI';
import { OfferHistorySortTypeEnums } from '../../../../Types/Enums/OfferHistorySortTypeEnums';
import { OfferHistorySortOrderEnums } from '../../../../Types/Enums/OfferHistorySortOrderEnums';
import styles from './index.module.scss';

const RegisteredOffersPage = observer(() => {
  const [copiedOffer, setCopiedOffer] = useState<IOfferHistoryItem>();
  const [selectedOffer, setSelectedOffer] = useState<IOfferHistoryItem>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [insuranceTypeFilter, setInsuranceTypeFilter] = useState<string>("");
  const [createDateFilter, setCreateDateFilter] = useState<string>("");
  const [validityDateFilter, setValidityDateFilter] = useState<string>("");
  const [sortOrders, setSortOrders] = useState<any>({ user: OfferHistorySortOrderEnums.DESC, type_name: OfferHistorySortOrderEnums.DESC, offerDate: OfferHistorySortOrderEnums.DESC, offerValidityDate: OfferHistorySortOrderEnums.DESC });
  const [page, setPage] = useState<number | undefined>();
  const [pageSize, setPageSize] = useState<number | undefined>();
  const [totalPage, setTotalPage] = useState<number | undefined>();

  useEffect(() => {
    offerStore.fillOfferHistory(offerStore?.offerHistory.current_page, offerStore?.offerHistory.page_size, OfferHistorySortTypeEnums.OFFER_DATE, OfferHistorySortOrderEnums.DESC);
  }, []);

  const isStaff = useMemo(() => {
    return authStore.roles.includes(RoleEnum.Staff);
  }, [authStore.roles]);

  const getHistoryByTableSorter = (
    sorter: SorterResult<IOfferHistoryItem> | SorterResult<IOfferHistoryItem>[],
    page: number = 1,
    pageSize: number = 10
  ) => {
    if (!isArray(sorter)) {
      const { column, columnKey } = sorter;
      let key: React.Key | undefined = OfferHistorySortTypeEnums.OFFER_DATE;
      if (column) key = columnKey;
      // @ts-ignore
      offerStore.fillOfferHistory(page, pageSize, key, sortOrders[key], searchValue, insuranceTypeFilter, createDateFilter, validityDateFilter);
      // @ts-ignore
      !!key && setSortOrders({ ...sortOrders, [key]: sortOrders[key] === OfferHistorySortOrderEnums.ASC ? OfferHistorySortOrderEnums.DESC : OfferHistorySortOrderEnums.ASC });
      setPage(page || 1);
      setPageSize(pageSize || 10);
    }
  };

  const copyOffer = (offer: IOfferHistoryItem) => {
    setCopiedOffer(offer);
  };

  const handleChangeSearch = (value: string): void => setSearchValue(value);
  const handleChangeInsuranceType = (value: string): void => setInsuranceTypeFilter(value);
  const handleSelectCreateDate = (date: string): void => setCreateDateFilter(date);
  const handleSelectValidityDate = (date: string): void => setValidityDateFilter(date);

  const handleClickFilterButton = (): void => {
    offerStore.fillOfferHistory(1, 10, OfferHistorySortTypeEnums.OFFER_DATE, OfferHistorySortOrderEnums.DESC, searchValue, insuranceTypeFilter, createDateFilter, validityDateFilter);
  }

  const tableCols: ColumnsType<IOfferHistoryItem> = [
    {
      title: 'Müşteri',
      key: OfferHistorySortTypeEnums.USER,
      dataIndex: 'customer',
      render: (value, record) => {
        const customer = record?.offer_inside?.user_info;
        return customer?.isim_soyisim || customer?.unvan;
      },
      sorter: true
    },
    {
      title: 'Sigorta Türü',
      key: OfferHistorySortTypeEnums.TYPE_NAME,
      dataIndex: 'offerType',
      render: (_, record) => record.offer_inside?.type_name,
      sorter: true
    },
    {
      title: 'Oluşturulma Tarihi',
      key: OfferHistorySortTypeEnums.OFFER_DATE,
      dataIndex: 'createDate',
      render: (value, record) => record?.offer_inside?.offerDate || '-',
      sorter: (a, b) => a.date_created && b.date_created ? new Date(b.date_created).getMilliseconds() - new Date(a.date_created).getMilliseconds() : -1
    },
    {
      title: 'Geçerlilik Tarihi',
      key: OfferHistorySortTypeEnums.OFFER_VALIDITY_DATE,
      dataIndex: 'validityDate',
      render: (value, record) => record?.offer_inside?.offerValidityDate || '-',
      sorter: true
    },
    {
      title: 'İşlem',
      key: 'actions',
      dataIndex: 'actions',
      align: 'right',
      render: (_, record) => (
        <Row gutter={[10, 10]} justify="end">
          <Col>
            <EyeOutlined onClick={() => setSelectedOffer(record)} />
          </Col>
          <Col>
            <CopyOutlined onClick={() => copyOffer(record)} />
          </Col>
          {isStaff && (
            <Col>
              <DeleteOutlined onClick={() => offerStore.deleteOfferHistory(record?.id)} />
            </Col>
          )}
        </Row>
      )
    }
  ];

  useEffect(() => {
    if (!offerStore.offerHistoryLoading) {
      const { current_page, page_size, total_pages } = offerStore?.offerHistory;
      console.log(total_pages);
      setPage(current_page);
      setPageSize(page_size);
      setTotalPage(total_pages);
    }
  }, [offerStore.offerHistoryLoading]);

  return (
    <div>
      <Divider>
        <h2>Geçmiş Teklifler</h2>
      </Divider>
      <div className={styles.buttonContainer}>
        <div className={styles.input}>
          <SWInput
            id="searchInput"
            value={searchValue}
            onChange={handleChangeSearch}
            placeholder="Müşteri"
            type='text'
            height={34}
            style={{ border: { borderWidth: 1 }, fontSize: 12 }}
          />
        </div>
        <div className={styles.input}>
          <SWInput
            id="insuranceTypeInput"
            value={insuranceTypeFilter}
            onChange={handleChangeInsuranceType}
            placeholder="Sigorta Türü"
            type='text'
            height={34}
            style={{ border: { borderWidth: 1 }, fontSize: 12 }}
          />
        </div>
        <SWDatePicker
          label="Oluşturulma Tarihi"
          width={200}
          onSelect={handleSelectCreateDate}
        />
        <SWDatePicker
          label="Geçerlilik Tarihi"
          width={200}
          onSelect={handleSelectValidityDate}
        />
        <SWButton
          disabled={offerStore.offerHistoryLoading}
          content='FİLTRELE'
          size='middle'
          type='primary'
          onClick={handleClickFilterButton}
        />
      </div>
      <Table
        columns={tableCols}
        dataSource={[...offerStore?.offerHistory?.offers] || []}
        rowKey={(i) => i.offer_inside.id}
        loading={offerStore.offerHistoryLoading}
        pagination={{ current: page, pageSize: pageSize, total: (typeof totalPage === 'number' && typeof pageSize === 'number') ? totalPage * pageSize : 1 }}
        onChange={(pagination, filters, sorter) => {
          getHistoryByTableSorter(sorter, pagination.current, pagination.pageSize);
        }}
      />
      <SWEditOfferTemplateDrawer
        isOfferHistory
        refNo={selectedOffer?.ref_no}
        creator={selectedOffer?.creator}
        offerType={selectedOffer?.offer_inside}
        visible={typeof selectedOffer !== 'undefined'}
        onClose={() => setSelectedOffer(undefined)}
        title={`${selectedOffer?.ref_no || ''} - Geçmiş Teklif`}
      />
      <SWEditOfferTemplateDrawer
        isCopyOffer
        customerId={copiedOffer?.customer_id}
        offerType={copiedOffer?.offer_inside}
        visible={typeof copiedOffer !== 'undefined'}
        onClose={() => setCopiedOffer(undefined)}
        title={`Teklif Kopyala - ${copiedOffer?.offer_inside?.type_name} Sigortası`}
      />
    </div>
  );
});

export default RegisteredOffersPage;
