import React, { useEffect, useMemo, useState } from 'react';

import {
  MdOutlineBusiness,
  MdOutlineGroups,
  MdOutlineManageAccounts,
  MdOutlineReceiptLong,
  MdOutlineSpaceDashboard
} from 'react-icons/md';
import { MenuItemType } from 'antd/lib/menu/hooks/useItems';
import { useLocation, useNavigate } from 'react-router';
import { IoSettingsOutline } from 'react-icons/io5';
import { PiFoldersLight } from 'react-icons/pi';

import { RoleEnum } from '../Types/Enums/RoleEnum';
import { PageLink } from '../Constants/PageLink';
import { authStore } from '../GlobalStore';

type MenuItem = {
  key: string;
  label: string;
  visible?: boolean;
  icon?: React.ReactNode;
  path: PageLink | null;
  children?: Array<MenuItem>;
  onClick?: MenuItemType['onClick'];
};

const iconStyles = {
  width: 20,
  height: 20
};
const getItem = (params: MenuItem): MenuItem => {
  const { key, icon, children, label, onClick, path, visible } = params;
  return {
    key,
    path,
    icon,
    label,
    visible,
    onClick,
    children
  } as MenuItem;
};

export const useSideMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [openMenuKeys, setOpenMenuKeys] = useState<Array<string>>();
  const [selected, setSelected] = useState<Array<string>>([
    PageLink.CreateOffer
  ]);

  useEffect(() => {
    const currentPath = location?.pathname;
    let selectedMenuItem = menuItems?.find((item) => item?.key === currentPath);

    let selectedParentMenuKey: React.Key | undefined = undefined;
    if (!selectedMenuItem) {
      menuItems.map((m) => {
        // @ts-ignore
        selectedMenuItem = m?.children?.find((sm) => sm.key === currentPath);
        selectedParentMenuKey = m?.key;
      });
    }
    if (selectedParentMenuKey) setOpenMenuKeys([selectedParentMenuKey]);

    if (selectedMenuItem) {
      setSelected([selectedMenuItem?.key as PageLink]);
    }
  }, [location?.pathname]);

  const onOpenChange = (keys: Array<string>) => setOpenMenuKeys(keys);

  const isStandardUser = useMemo(
    () => authStore.roles.includes(RoleEnum.Standard),
    [authStore.roles]
  );

  const menuItems: MenuItem[] = [
    getItem({
      label: 'Teklif Oluştur',
      key: PageLink.CreateOffer,
      path: PageLink.CreateOffer,
      icon: <MdOutlineReceiptLong style={iconStyles} />,
      onClick: (info) => navigate(info.key),
      visible: true
    }),
    getItem({
      label: 'Geçmiş Teklifler',
      key: PageLink.RegisteredOffers,
      path: PageLink.RegisteredOffers,
      icon: <PiFoldersLight style={iconStyles} />,
      onClick: (info) => navigate(info.key),
      visible: true
    }),
    getItem({
      label: 'Müşteriler',
      key: PageLink.Customers,
      path: PageLink.Customers,
      icon: <MdOutlineGroups style={iconStyles} />,
      onClick: (info) => navigate(info.key),
      visible: true
    }),
    getItem({
      label: 'Ayarlar', //settings
      key: 'settings',
      path: null,
      icon: <IoSettingsOutline style={iconStyles} />,
      visible: !isStandardUser,
      children: [
        getItem({
          label: 'Teklif Şablonları',
          key: PageLink.OfferTemplates,
          path: PageLink.OfferTemplates,
          icon: <MdOutlineSpaceDashboard style={iconStyles} fill={'white'} />,
          onClick: (info) => navigate(info.key),
          visible: !isStandardUser
        }),
        getItem({
          label: 'Sigorta Şirketleri',
          key: PageLink.InsuranceCompanies,
          path: PageLink.InsuranceCompanies,
          icon: <MdOutlineBusiness style={iconStyles} />,
          onClick: (info) => navigate(info.key),
          visible: !isStandardUser
        }),
        getItem({
          label: 'Kullanıcı Yönetimi',
          key: PageLink.UserManagement,
          path: PageLink.UserManagement,
          icon: <MdOutlineManageAccounts style={iconStyles} />,
          onClick: (info) => navigate(info.key),
          visible: !isStandardUser
        })
      ]
    })
  ];
  return {
    menuItems,
    selectedKeys: selected,
    defaultOpenKeys: ['settings'],
    setOpenMenuKeys,
    onOpenChange,
    openMenuKeys
  };
};
