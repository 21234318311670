import * as yup from 'yup';
import { isEmpty, isNil } from 'lodash';
import moment from 'moment-timezone';

yup.addMethod(yup.boolean, 'mustTrue', function () {
  return this.test({
    message: 'Its must true',
    test: (value) => typeof value === 'boolean' && value === true
  });
});

yup.addMethod(yup.string, 'realEmail', function () {
  return this.test({
    message: 'Geçerli bir e-mail adresi giriniz',
    test: (value) =>
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        <string>value
      )
  });
});

yup.addMethod(yup.string, 'strongPassword', function (message?: string) {
  return this.test({
    message:
      message ||
      'Şifre en az 8 karakter olmalı. En az 1 özel karakter, 1 büyük harf ve 1 rakam içermeli',
    test: (value, context) => {
      const { optional, nullable } = context.schema?.spec;
      if ([optional, nullable].includes(true) && !value) return true;
      return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$!~'%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,15}$/.test(
        <string>value
      );
    }
  });
});

yup.addMethod(yup.string, 'onlyAlphabetic', function () {
  return this.test({
    message: 'Yalnızca alfabetik değerler içermeli',
    test: (value) => /^[A-Za-zşŞıİçÇöÖüÜĞğ ]+$/.test(value || '')
  });
});

yup.addMethod(yup.string, 'required', function () {
  return this.test({
    message: 'Bu alanın doldurulması zorunludur',
    test: (value) =>
      typeof value !== 'undefined' && !isNil(value) && !isEmpty(value)
  });
});

yup.addMethod(yup.string, 'min', function (min: number) {
  return this.test({
    message: `Minimum ${min} karakterden oluşmalı`,
    test: (value) => !!(value?.length && value.length >= min)
  });
});

yup.addMethod(yup.string, 'identityNumber', function () {
  return this.test({
    message: `11 haneli, geçerli bir T.C. kimlik numarası giriniz`,
    test: (value) => /^[1-9]{1}[0-9]{9}[02468]{1}$/.test(value || '')
  });
});

yup.addMethod(yup.string, 'taxNumber', function () {
  return this.test({
    message: `10 haneli vergi numaranızı giriniz`,
    test: (value) => /^[0-9]{10}$/.test(value || '')
  });
});

yup.addMethod(yup.string, 'phoneNumber', function (isHomePhone: boolean) {
  let regex = /^(5)([0-9]{2})\s?([0-9]{3})\s?([0-9]{2})\s?([0-9]{2})$/;
  if (isHomePhone)
    regex = /^(0)([2348]{1})([0-9]{2})\s?([0-9]{3})\s?([0-9]{2})\s?([0-9]{2})$/;

  return this.test({
    message: `Geçerli bir telefon numarası giriniz`,
    test: (value) => regex.test(value || '')
  });
});

yup.addMethod(yup.string, 'birthDate', function () {
  return this.test({
    message: 'Doğum tarihi 18 yaşından büyük olacak şekilde girilmeli',
    test: (value) =>
      Math.floor(
        moment(new Date().toJSON()).diff(moment(value), 'years', true)
      ) >= 18
  });
});

export default yup;
