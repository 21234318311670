import { useLocation, useNavigate } from 'react-router';
import React, { useEffect, useState } from 'react';

import { toast, Toaster, useToasterStore } from 'react-hot-toast';
import { ConfigProvider } from 'antd';
import { observer } from 'mobx-react';

import { isStandardUserAuthorizeDeniedFromRoute } from '../Helpers/roleHelpers';
import { authRoutes, PageLink } from '../Constants/PageLink';
import { ToastConfig } from '../Constants/ToastConfig';
import { ThemeColors } from '../Constants/Theme';
import { authStore } from '../GlobalStore';
import GuestRouter from './GuestRouter';
import AuthRouter from './AuthRouter';

export const AppRouter = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const { toasts } = useToasterStore();

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();

  useEffect(() => {
    const hasAuthToken =
      typeof (window?.localStorage.getItem('@AuthToken') || null) === 'string';
    authStore.isAuthenticated = true;
    setIsAuthenticated(hasAuthToken);
  }, []);

  useEffect(() => {
    const currentPath = location?.pathname;
    if (typeof isAuthenticated === 'boolean') {
      const isMustAuth = authRoutes.some((route) =>
        route.startsWith(currentPath)
      );

      if (isMustAuth && !isAuthenticated) {
        navigate(PageLink.Login);
      } else if (!isMustAuth && isAuthenticated) {
        navigate(PageLink.CreateOffer);
      }
    }
  }, [isAuthenticated, authStore.roles]);

  useEffect(() => {
    const currentPath = location.pathname;
    if (isStandardUserAuthorizeDeniedFromRoute(currentPath)) {
      navigate(PageLink.CreateOffer);
    }
  }, [location.pathname, authStore.roles]);

  useEffect(() => {
    toasts
      .filter((t) => t.visible)
      .filter((_, i) => i >= 5)
      .forEach((t) => toast.dismiss(t.id));
  }, [toasts]);

  if (typeof isAuthenticated !== 'boolean') return null;
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: ThemeColors.Primary,
          borderRadius: 4,
          colorPrimaryHover: ThemeColors.Primary,
          colorBgLayout: '#263c8f'
        }
      }}>
      {isAuthenticated ? <AuthRouter /> : <GuestRouter />}
      <Toaster gutter={8} position={'top-right'} toastOptions={ToastConfig} />
    </ConfigProvider>
  );
});
